import { ROUTES } from '../../types/routes';
import { mapClassnameForMobile } from '../../utils';
import { HeaderHistoryLayout } from '../common/header';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useTranslation } from 'react-i18next';
import { HistoryNavigationContainer } from '../common/navigation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	clearDatePeriod,
	selectHistoryCurrentTab,
	selectHistoryCurrentTimeFrame,
	selectHistoryTabs,
	setCurrentOperation,
	setDatePeriod,
	setHistoryTab,
	setHistoryTimeFrame,
} from '../../services/history';
import {
	THistoryDatePeriodWithDate,
	THistoryNameTab,
	THistoryPeriod,
	TOperation,
} from '../../types/types';
import { useGetDefaultHistory } from '../../data/mutations/history';
import { HistoryItemList } from '../common/lists';
import { useEffect, useState } from 'react';
import {
	GenerateStatementModal,
	HistoryCustomPeriodModal,
	HistoryOperationDetailsModal,
	HistoryPeriodModal,
	LocaleModal,
	RejectOperationModal,
} from '../common/modals';
import { useDebounce } from '../../hooks/useDebounce';
import { selectLocale } from '../../services/root';

const HistoryLayout = () => {
	const locale = useAppSelector(selectLocale);
	const currentTab = useAppSelector(selectHistoryCurrentTab);
	const historyTabs = useAppSelector(selectHistoryTabs);
	const historyTimeFrame = useAppSelector(selectHistoryCurrentTimeFrame);

	const dispatch = useAppDispatch();

	const [searchValue, setSearchValue] = useState<string>('');

	const [operId, setOperId] = useState<string>('');

	const debouncedValue = useDebounce(searchValue, 500);

	const {
		isLoading,
		getOperationsByTabChange,
		getOperationsByPeriodChange,
		getOperationsByFindChange,
		getOperationDetails,
		getOperationsByDatePeriod,
	} = useGetDefaultHistory();

	const handleHistoryPageInit = async () => {
		await getOperationsByTabChange('all');
	};

	useEffect(() => {
		if (currentTab?.server?.toLowerCase() === 'all') {
			handleHistoryPageInit();
		}
	}, []);

	const [localLocale, setLocalLocale] = useState<string>(locale);

	const [isHistoryPeriodModalOpen, setIsHistoryPeriodModalOpen] =
		useState<boolean>(false);

	const [isHistoryOperationDetailsModalOpen, setIsHistoryOperationDetailsModalOpen] =
		useState<boolean>(false);

	const [isHistoryRejectModalOpen, setIsHistoryRejectModalOpen] =
		useState<boolean>(false);

	const [isHistoryCustomPeriodModalOpen, setIsHistoryCustomPeriodModalOpen] =
		useState<boolean>(false);

	const [isStatementModalOpen, setIsStatementModalOpen] = useState<boolean>(false);

	const [isLocaleModalOpen, setIsLocaleModalOpen] = useState<boolean>(false);

	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const handleBackClickButton = () => {
		dispatch(
			setHistoryTab({
				name: historyTabs[locale][0],
				server: historyTabs['en'][0],
			})
		);
		dispatch(setHistoryTimeFrame(undefined));
		navigate(ROUTES.WALLET.INDEX);
	};

	const handleLocaleModalOpen = () => {
		setIsLocaleModalOpen(!isLocaleModalOpen);
	};

	const handleHistoryPeriodModalOpen = () => {
		setIsHistoryPeriodModalOpen(!isHistoryPeriodModalOpen);
	};

	const handleHistoryOperationDetailsModalOpen = () => {
		setIsHistoryOperationDetailsModalOpen(!isHistoryOperationDetailsModalOpen);
	};

	const handleIsStatementModalOpen = () => {
		setIsStatementModalOpen(!isStatementModalOpen);
	};

	const handleHistoryRejectModalOpen = (operId?: string) => {
		if (operId) {
			setOperId(operId);
		}
		handleHistoryOperationDetailsModalOpen();
		setIsHistoryRejectModalOpen(!isHistoryRejectModalOpen);
	};

	const handleHistoryPeriodChange = (timeFrame: THistoryPeriod) => {
		dispatch(setHistoryTimeFrame(timeFrame));
		dispatch(clearDatePeriod());
		getOperationsByPeriodChange(timeFrame);
		handleHistoryPeriodModalOpen();
	};

	const handleLocaleItemClick = (i: string) => {
		setIsLocaleModalOpen(false);
		setLocalLocale(i);
	};

	const handleCustomPeriodModalOpen = () => {
		handleHistoryPeriodModalOpen();
		setIsHistoryCustomPeriodModalOpen(!isHistoryCustomPeriodModalOpen);
	};

	const handleResetClick = () => {
		dispatch(setHistoryTimeFrame(undefined));
		getOperationsByPeriodChange(undefined);
	};

	const handleNavigationTabChange = (tab: THistoryNameTab) => {
		const localTab = {
			name: tab,
			server: historyTabs['en'][historyTabs[locale].indexOf(tab as never)],
		};
		dispatch(setHistoryTab(localTab));
		getOperationsByTabChange(localTab.server);
	};

	const handleItemClick = (i: TOperation) => {
		getOperationDetails(i.operaionid);
		dispatch(setCurrentOperation(i));
		setIsHistoryOperationDetailsModalOpen(!isHistoryOperationDetailsModalOpen);
	};

	const handleHistoryPeriodClick = (e: THistoryDatePeriodWithDate) => {
		const datePeriod = {
			dateFrom: e.dateFrom?.format('DD/MM/YYYY').replaceAll('/', '') as string,
			dateTo: e.dateTo?.format('DD/MM/YYYY').replaceAll('/', '') as string,
			fullDateFrom: e.fullDateFrom,
			fullDateTo: e.fullDateTo,
		};
		dispatch(setDatePeriod(datePeriod));
		dispatch(
			setHistoryTimeFrame({
				shortName:
					e.dateFrom?.format('DD/MM').replaceAll('/', '.') +
					' - ' +
					e.dateTo?.format('DD/MM').replaceAll('/', '.'),
				datefilter: 'period',
			})
		);
		getOperationsByDatePeriod(datePeriod);
		setIsHistoryCustomPeriodModalOpen(false);
	};

	useEffect(() => {
		if (debouncedValue.length > 2) {
			getOperationsByFindChange(debouncedValue);
		}
	}, [debouncedValue]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleClearClick = () => {
		setSearchValue('');
	};

	return (
		<div className={'component'}>
			<div style={{ height: 'auto' }} className={'innerComponent'}>
				<HeaderHistoryLayout
					handleStatementClick={handleIsStatementModalOpen}
					handleInputChange={handleInputChange}
					handleClearClick={handleClearClick}
					searchValue={searchValue}
					handleBackClick={handleBackClickButton}
				/>

				<LocaleModal
					isModalOpen={isLocaleModalOpen}
					handleIsModalOpen={handleLocaleModalOpen}
					handleItemClick={handleLocaleItemClick}
				/>

				<GenerateStatementModal
					handleIsModalOpen={handleIsStatementModalOpen}
					isModalOpen={isStatementModalOpen}
					locale={localLocale}
					handleLocaleModalOpen={handleLocaleModalOpen}
				/>

				<HistoryPeriodModal
					isModalOpen={isHistoryPeriodModalOpen}
					handleIsModalOpen={handleHistoryPeriodModalOpen}
					handleItemClick={handleHistoryPeriodChange}
					handleCustomPeriodModalOpen={handleCustomPeriodModalOpen}
					handleResetClick={handleResetClick}
					currentTimeFrame={historyTimeFrame}
				/>

				<HistoryCustomPeriodModal
					isModalOpen={isHistoryCustomPeriodModalOpen}
					handleIsModalOpen={handleCustomPeriodModalOpen}
					handleSubmit={handleHistoryPeriodClick}
				/>

				<HistoryOperationDetailsModal
					isModalOpen={isHistoryOperationDetailsModalOpen}
					handleIsModalOpen={handleHistoryOperationDetailsModalOpen}
					handleRejectOperation={handleHistoryRejectModalOpen}
				/>

				<RejectOperationModal
					handleIsModalOpen={handleHistoryRejectModalOpen}
					isModalOpen={isHistoryRejectModalOpen}
					operId={operId}
				/>

				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">{t('history_title')}</h1>

						<HistoryNavigationContainer
							isLoading={isLoading}
							currentTab={currentTab.name}
							historyTabs={historyTabs}
							handleTabChange={handleNavigationTabChange}
							currentTimeFrame={
								historyTimeFrame != undefined
									? historyTimeFrame.shortName
									: ''
							}
							handleTimeFrameClick={handleHistoryPeriodModalOpen}
						/>

						<HistoryItemList
							handleItemClick={handleItemClick}
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HistoryLayout;
