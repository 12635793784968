export const ROUTES = {
	MAIN: {
		INDEX: '/:locale/main',
	},
	PROFILE: {
		INDEX: '/:locale/profile',
		APP_SETTINGS: '/:locale/profile/settings',
		ACCOUNT_INFO: '/:locale/profile/info',
		DOCUMENTS: '/:locale/profile/documents',
	},
	HISTORY: {
		INDEX: '/:locale/history',
	},
	MAINTAIN: {
		INDEX: '/:locale/maintain',
	},
	WALLET: {
		INDEX: '/:locale/wallet',
	},
	EXCHANGE: {
		INDEX: '/:locale/exchange',
	},
	MARKET: {
		INDEX: '/:locale/market',
		ASSET_DETAILS: '/:locale/market/:code',
	},
	SEARCH: {
		INDEX: '/:locale/search',
	},
	ROOT: {
		INDEX: '/',
	},
	INACTIVE: {
		INDEX: '/:locale/inactive',
	},
	AUTH: {
		INDEX: '/:locale/auth',
		LOGIN: '/:locale/auth/login',
		TWO_FA: '/:locale/auth/login/2fa',
		OTP: '/:locale/auth/login/otp',
		SETUP_BIOMETRY: '/:locale/auth/login/biometry',
		SETUP_TWOFA: '/:locale/auth/login/setupTwoFa',
		SECRET_KEY: '/:locale/auth/login/secretKey',
		CONFIRM_TWOFA: '/:locale/auth/login/confirmTwoFa',
		SELECT_TWOFA: '/:locale/auth/login/selectTwoFa',
		IMPERSONATE: '/:locale/auth/login/impersonate',
		PASSWORD_RECOVERY: '/:locale/auth/login/recovery',
		CONFIRM_EMAIL: '/:locale/auth/login/recovery/confirm',
		NEW_PASSWORD: '/:locale/auth/login/recovery/password',
	},
	ABOUT: {
		INDEX: '/:locale/about',
		HELP: '/:locale/about/help',
		LEGAL_STATEMENTS: '/:locale/about/statements',
		COMMUNITY: '/:locale/about/community',
	},
	DEPOSIT: {
		INDEX: '/:locale/deposit',
		ERIP: '/:locale/deposit/erip',
		OPLATI: '/:locale/deposit/oplati',
		CARD: '/:locale/deposit/card',
		CRYPTO: '/:locale/deposit/crypto',
		BANK_TRANSFER: '/:locale/deposit/transfer',
		BANK_TRANSFER_INVOICE: '/:locale/deposit/transfer/:invoiceId',
		BANK_TRANSFER_INVOICE_HISTORY: '/:locale/deposit/transfer/history'
	},
	WITHDRAW: {
		INDEX: '/:locale/withdraw',
		CARD: '/:locale/withdraw/card',
		CRYPTO: {
			INDEX: '/:locale/withdraw/crypto',
			CONFIRMATION: '/:locale/withdraw/crypto/confirmation',
			TWO_FA: '/:locale/withdraw/crypto/twofa',
			SUMMARY: '/:locale/withdraw/crypto/summary'
		},
		BANK_TRANSFER: '/:locale/withdraw/transfer',
	},
};

export const GA_ROUTES = {
	APPLE: 'https://apps.apple.com/ru/app/google-authenticator/id388497605',
	ANDROID:
		'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1',
};

export const COMMON_ROUTES = {
	LK: 'https://my.free2ex.com/',
};
