import { useTranslation } from 'react-i18next';
import {
	TAsset,
	TBankCountry,
	TCountry,
	THistoryDatePeriod,
	THistoryDatePeriodWithDate,
	THistoryPeriod,
	TInnerBank,
	TInnerCryptoDeposit,
	TInnerCryptoDepositAsset,
	TInnerCryptoWithdraw,
	TInnerCryptoWithdrawAsset,
	TOperation,
	TSavedAddress,
	TTransferParams,
	TWalletModalItem,
} from '../../../types/types';
import { Backdrop, Fade, Modal } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { CustomInput } from '../inputs';
import { CountryModalItem, HistoryPeriodModalItem, OTPModalItem } from './items';
import {
	CopyGreenIcon,
	CopyIcon,
	EllipseCheckBoxIcon,
	OperationStatusIcon,
	RightChev,
	SelectedTimeFrameIcon,
	ThemeIcons,
	VerificationStarIcon,
	WhaleIcon,
} from '../icons';
import { CustomButton } from '../buttons';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectBanksDictionary,
	selectCountriesDictionary,
	selectDepositWithdrawAssetsDictionary,
	selectPeriodFiltersDictionary,
} from '../../../services/dictionaries';
import {
	selectAppSettings,
	selectLocale,
	selectTheme,
	setTheme,
} from '../../../services/root';
import { MarketAssetItem, WalletItem, WalletModalItem } from '../lists/items';
import { HistoryItemList, SavedAddressItemList, WalletItemList } from '../lists';
import { CustomCheckbox } from '../checkboxes';
import {
	clearConvertSelected,
	selectConvertAll,
	selectBaseLowBalance,
	selectBasePrecision,
	selectConvertOne,
	selectWalletConvertAssets,
	selectTransferOne,
	clearTransferSelected,
	selectTransferAll,
	selectWalletTransferToMainRestsAssets,
	selectMainEWallet,
	selectTotalAccountsRests,
	selectIsLeverageTransfer,
	selectSpotAccountsRests,
	selectCommissionAccountRests,
	selectGlobalAsset,
	selectMainAccountRests,
} from '../../../services/wallet';
import {
	useConvertLowBalancesMutation,
	useTransferToMainRestsMutation,
} from '../../../data/mutations/wallet';
import { selectAccessToken } from '../../../services/auth';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { APIError } from '../error';
import { formatNumber } from '../../../utils';
import { CustomSwitch } from '../switchs';
import {
	useRevokeTokenMutation,
	useSendGeneralAccountLetterMutation,
	useSendStatementMutation,
} from '../../../data/mutations/profile';
import { selectIsVerified } from '../../../services/user';
import { COMMON_ROUTES, ROUTES } from '../../../types/routes';
import {
	selectCurrentOperation,
	selectDatePeriod,
	selectOperationDetails,
	setOperationDetails,
} from '../../../services/history';
import {
	useGetDefaultHistory,
	useRejectOperation,
} from '../../../data/mutations/history';
import dayjs, { Dayjs } from 'dayjs';
import CustomDatePicker from '../date-pickers';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import { selectMarketAssets } from '../../../services/market';
import { selectCurrentInvoiceId, selectInvoiceInfo } from '../../../services/deposit';
import { BankTransferInvoicePreviewLayout } from '../../deposit/bank/invoice';
import { useDeleteBankDepositInvoice } from '../../../data/mutations/deposit';
import {
	useDeleteCryptoWithdrawSavedAddresses,
	useGetCryptoWithdrawSavedAddresses,
	useUpdateCryptoWithdrawSavedAddresses,
} from '../../../data/mutations/withdraw';
import {
	selectCryptoWithdrawCurrentSavedAddress,
	selectCryptoWithdrawNetwork,
} from '../../../services/withdraw';

interface BaseModalProps {
	isModalOpen: boolean;
	handleIsModalOpen: () => void;
}

interface CountryModalProps extends BaseModalProps {
	handleItemClick: (i: { code: string; imgUrl: string }) => void;
	countries: TCountry[];
}

const CountryModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	countries,
}: CountryModalProps) => {
	const { t } = useTranslation();

	const [searchValue, setSearchValue] = useState<string>('');
	const [isSearchValueRequired, setIsSearchValueRequired] = useState<boolean>(false);

	const [mappedData, setMappedData] = useState<
		{
			code: string;
			name: string;
			link: string;
			phoneCode: string;
			isRestricted: boolean;
		}[]
	>();

	const [modalInset, setModalInset] = useState<string>('');

	useEffect(() => {
		setMappedData(countries);
	}, []);

	useEffect(() => {
		if (window.innerWidth < 480) {
			if (window.innerHeight < 670) {
				setModalInset((window.innerHeight * 425) / 540 + 'px');
				return;
			} else if (window.innerHeight > 670 && window.innerHeight < 916) {
				setModalInset((window.innerHeight * 280) / 684 + 'px');
				return;
			}
			setModalInset('215px');
		} else {
			setModalInset('545px');
		}
	}, [window.innerHeight]);

	useEffect(() => {
		setMappedData(
			countries.filter(
				(item: {
					code: string;
					name: string;
					link: string;
					phoneCode: string;
					isRestricted: boolean;
				}) =>
					item.code.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.phoneCode.toLowerCase().includes(searchValue.toLowerCase())
			)
		);
	}, [searchValue]);

	const handleModalClose = () => {
		handleIsModalOpen();
		setSearchValue('');
		setIsSearchValueRequired(false);
	};

	const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
		setIsSearchValueRequired(e.target.value.length > 0);
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: modalInset,
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponent'}>
					<div className={''}>
						<CustomInput
							className={'modalInput'}
							id={'countryModalSearchInput'}
							value={searchValue}
							label={t('registrationSearch')}
							isRequired={isSearchValueRequired}
							type={'text'}
							onChange={handleSearchValueChange}
						/>
					</div>
					{mappedData && mappedData?.length > 0 ? (
						<div className={'modalContent'}>
							{mappedData?.map((item) => (
								<CountryModalItem
									key={item.code}
									handleModalOpen={handleIsModalOpen}
									item={item}
									handleItemClick={handleItemClick}
									setSearchValue={setSearchValue}
								/>
							))}
						</div>
					) : (
						<div className={'modalContent-empty'}>
							<div className={'modalEmptyContainer'}>
								<WhaleIcon className={'whaleIcon'} />
								<h1 className={'modalEmptyHeader'}>
									{t('exchangeNotFound')}
								</h1>
								<h1 className={'modalEmptyBottom'}>
									{t('exchangeChangeSettingsText')}
								</h1>
							</div>
						</div>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

interface OTPModalProps extends BaseModalProps {
	handleItemClick: (i: { key: string; value: string }) => void;
	identityTypes: {
		key: string;
		value: string;
	}[];
}

const OTPModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	identityTypes,
}: OTPModalProps) => {
	const [mappedData, setMappedData] = useState<
		{
			key: string;
			value: string;
		}[]
	>();

	useEffect(() => {
		setMappedData(identityTypes);
	}, []);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalOtpComponent'}>
					<div className={'modalContent'}>
						{mappedData?.map((item) => (
							<OTPModalItem
								key={item.key}
								handleModalOpen={handleIsModalOpen}
								item={item}
								handleItemClick={handleItemClick}
							/>
						))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface ConfirmRegistrationModalProps extends BaseModalProps {
	handleConfirm: () => void;
	isLoading: boolean;
	text?: string;
}

const ConfirmRegistrationModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleConfirm,
	isLoading,
	text,
}: ConfirmRegistrationModalProps) => {
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalOtpComponent'}>
					<h1 className={'confirmEmailText'}>
						{text ? text : t('confirmModalHeader')}
					</h1>
					<div className={'modalContent'}>
						<div className={'confirmButtonContainer'}>
							<CustomButton
								isGrey
								text={t('confirmModalFirstButton') as string}
								type={'submit'}
								onClick={handleIsModalOpen}
								className={'confirmButton'}
							/>
							<CustomButton
								text={t('confirmModalSecondButton') as string}
								type={'submit'}
								onClick={handleConfirm}
								className={'confirmButton'}
								isLoading={isLoading}
								isDisabled={false}
							/>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface MarketTimeFrameModalProps extends BaseModalProps {
	handleItemClick: (i: string) => void;
	currentTimeFrame: string;
}

const MarketTimeFrameModal = ({
	handleIsModalOpen,
	handleItemClick,
	isModalOpen,
	currentTimeFrame,
}: MarketTimeFrameModalProps) => {
	const { t } = useTranslation();

	const timeFrames = [
		{
			text: `1 ${t('filters_hour')}`,
			toSelectValue: `1${t('filters_h')}`,
		},
		{
			text: `24 ${t('filters_hours')}`,
			toSelectValue: `24${t('filters_h')}`,
		},
		{
			text: `7 ${t('filters_days')}`,
			toSelectValue: `7${t('filters_d')}`,
		},
		{
			text: `30 ${t('filters_days')}`,
			toSelectValue: `30${t('filters_d')}`,
		},
	];

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};
	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalMarketTimeFrameComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('filters_timeframe')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalMarketTimeFrameInnerContainer'}>
						{timeFrames.map((timeFrame, idx) => (
							<div
								className="modalMarketTimeFrameInnerItem"
								onClick={() => handleItemClick(timeFrame.toSelectValue)}
								key={idx}
							>
								<h1 className="modalMarketTimeFrameInnerText">
									{timeFrame.text}
								</h1>
								{timeFrame.toSelectValue === currentTimeFrame && (
									<SelectedTimeFrameIcon />
								)}
							</div>
						))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const ThemeModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: LocaleModalProps) => {
	const { t } = useTranslation();
	const theme = useAppSelector(selectTheme);
	const [isActive, setIsActive] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleDeviceThemeClick = () => {
		const tempIsActive = !isActive;
		setIsActive(tempIsActive);
		if (tempIsActive) {
			const darkThemeMq = window.matchMedia(
				'(prefers-color-scheme: dark)'
			)?.matches;
			dispatch(setTheme(darkThemeMq ? 'dark' : 'light'));
		} else {
			dispatch(setTheme('light'));
		}
	};

	useEffect(() => {
		if (isModalOpen) {
			if (
				window.matchMedia('(prefers-color-scheme: dark)')?.matches &&
				theme === 'dark'
			) {
				setIsActive(true);
			} else if (
				!window.matchMedia('(prefers-color-scheme: dark)')?.matches &&
				theme === 'light'
			) {
				setIsActive(true);
			}
		}
	}, [isModalOpen]);

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalThemeComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('settings_theme')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className="modalThemeOuterContainer">
						<div className={'modalThemeInnerContainer'}>
							<div className="themeHeaderTextContainer">
								<h1 className="themeHeaderMainText">
									{t('settings_useDeviceSettings')}
								</h1>
								<h1 className="themeHeaderBottomText">
									{t('settings_appWillUseDeviceMode')}
								</h1>
							</div>
							<div className="">
								<CustomSwitch
									setIsActive={setIsActive}
									isActive={isActive}
									onClick={handleDeviceThemeClick}
								/>
							</div>
						</div>
						<div className="modalThemeSelectContainer">
							<div
								onClick={() => handleItemClick('light')}
								className="modalThemeInnerSelectContainer"
							>
								<ThemeIcons.LightThemeSelectIcon />
								<h1 className="modalThemeInnerSelectText">
									{t('settings_light')}
								</h1>
								<EllipseCheckBoxIcon
									className="themeCheckboxIcon"
									isActive={theme === 'light'}
								/>
							</div>
							<div
								onClick={() => handleItemClick('dark')}
								className="modalThemeInnerSelectContainer"
							>
								<ThemeIcons.DarkThemeSelectIcon />
								<h1 className="modalThemeInnerSelectText">
									{t('settings_dark')}
								</h1>
								<EllipseCheckBoxIcon
									className="themeCheckboxIcon"
									isActive={theme === 'dark'}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface LocaleModalProps extends BaseModalProps {
	handleItemClick: (i: string) => void;
}

const LocaleModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: LocaleModalProps) => {
	const countries = useAppSelector(selectCountriesDictionary);
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalOtpComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('settings_appLanguage')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						<div
							className="modalLocaleItem"
							onClick={() => handleItemClick('en')}
						>
							<img
								className="countryImage"
								src={
									countries?.dictionary?.find(
										(country: TCountry) => country?.code === 'US'
									)?.link
								}
								alt="US"
							/>
							<h1 className="modalLocaleText">{t('general_english')}</h1>
						</div>
						<div
							className="modalLocaleItem"
							onClick={() => handleItemClick('ru')}
						>
							<img
								className="countryImage"
								src={
									countries?.dictionary?.find(
										(country: TCountry) => country?.code === 'RU'
									)?.link
								}
								alt="RU"
							/>
							<h1 className="modalLocaleText">{t('general_russian')}</h1>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface WalletConvertModalProps extends BaseModalProps {
	globalAsset: TWalletModalItem;
}

const WalletConvertModal = ({
	globalAsset,
	isModalOpen,
	handleIsModalOpen,
}: WalletConvertModalProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const walletConvertAssets = useAppSelector(selectWalletConvertAssets);
	const basePrecision = useAppSelector(selectBasePrecision);
	const accessToken = useAppSelector(selectAccessToken);
	const baseLowBalance = useAppSelector(selectBaseLowBalance);

	const [isSummary, setIsSummary] = useState<boolean>(false);
	const [selectedAmount, setSelectedAmount] = useState<number>(0);

	const { convertLowBalances, isError, isPending, error } =
		useConvertLowBalancesMutation(setIsSummary);

	const handleSelectedAmountChange = (i: TAsset, isSelected: boolean, idx: number) => {
		dispatch(
			selectConvertOne({
				idx: idx,
				checked: isSelected,
			})
		);

		if (isSelected) {
			setSelectedAmount(selectedAmount + i.baseAvailable);
		} else {
			setSelectedAmount(selectedAmount - i.baseAvailable);
		}
	};

	const handleModalClose = () => {
		dispatch(clearConvertSelected());
		setIsSummary(false);
		setSelectedAmount(0);
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleAllSelectedClick = (isSelectedEventValue: boolean) => {
		dispatch(selectConvertAll(isSelectedEventValue));

		if (isSelectedEventValue) {
			setSelectedAmount(
				walletConvertAssets.assets.reduce((currentSum, asset) => {
					return currentSum + asset.baseAvailable;
				}, 0)
			);
		} else {
			setSelectedAmount(0);
		}
	};

	const handleSubmit = () => {
		convertLowBalances({
			accessToken: accessToken as string,
			navigate: navigate,
			assets: walletConvertAssets.assets
				.filter((asset, idx) => walletConvertAssets.selected[idx] === true)
				.map((asset) => {
					return asset.code;
				}),
		});
	};

	if (isSummary) {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div className={'modalWalletConvertComponent'}>
						<WhaleIcon className="whaleConvertIcon" />
						<h1 className={'modalWalletConvertSummaryHeader'}>
							{t('operation_conversionProcessedTitle')}
						</h1>
						{isError ? (
							<APIError isError={isError} error={error} />
						) : (
							<h1 className={'modalWalletConvertSummaryBottom'}>
								{t('operation_conversionProcessedDetails')}
							</h1>
						)}

						<CustomButton
							className="closeFullWidthButton"
							isGrey
							text={t('notifications_close') as string}
							onClick={handleModalClose}
						/>
					</div>
				</Fade>
			</Modal>
		);
	}

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletConvertComponent'}>
					<div className={'modalWalletSearchHeader'}>
						<h1 className={'confirmEmailText'}>
							{t('wallet_convertLowBalances_medium')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					{walletConvertAssets.assets.length === 0 ? (
						<div className={'modalWalletSearchContent'}>
							<h1 className="modalWalletEmptyText">
								{t('wallet_convertLowBalancesEmpty')
									.replaceAll('{baseAssetCode}', globalAsset.code)
									.replaceAll(
										'{baseLowBalance}',
										baseLowBalance.toString()
									)}
							</h1>
							<CustomButton
								className=""
								onClick={handleModalClose}
								text={t('general_close') as string}
								isText
							/>
						</div>
					) : (
						<>
							<h1 className="walletConvertModalReceivedContainerHeader">
								{t('wallet_convertLowBalancesDesc')}
							</h1>
							<div className="modalWalletSearchContent">
								<div className="modalWalletConvertSelectAllContainer">
									<CustomCheckbox
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => handleAllSelectedClick(e.target.checked)}
									/>
									<h1 className="modalWalletConvertSelectAllText">
										{t('wallet_selectAllAssets')}
									</h1>
								</div>
								<WalletItemList
									isBalanceHidden={false}
									globalAsset={globalAsset}
									isLowBalanceHidden={false}
									isWalletConvertModal
									handleSelectedAmountChange={
										handleSelectedAmountChange
									}
									selected={walletConvertAssets.selected}
								/>
							</div>
							<div className="walletConvertModalBottomContainer">
								<div className="walletConvertModalReceivedContainer">
									<h1 className="walletConvertModalReceivedContainerHeader">
										{t('withdrawConfirmation_toBeReceived')}
									</h1>
									<h1 className="walletConvertModalReceivedContainerBottom">
										{formatNumber(selectedAmount, basePrecision)}{' '}
										{globalAsset.code}
									</h1>
								</div>
								<CustomButton
									isDisabled={
										!walletConvertAssets.selected.find(
											(isSelected) => isSelected === true
										)
									}
									text={t('wallet_convert_small') as string}
									isLoading={isPending}
									onClick={handleSubmit}
									className="convertButton"
								/>
							</div>
						</>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

interface AssetDetailsTransferModalProps extends BaseModalProps {
	assetCode: string;
}

const AssetDetailsTransferModal = ({
	assetCode,
	isModalOpen,
	handleIsModalOpen,
}: AssetDetailsTransferModalProps) => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const accessToken = useAppSelector(selectAccessToken);
	const mainEWallet = useAppSelector(selectMainEWallet);
	const totalAccountsRests = useAppSelector(selectTotalAccountsRests);

	const { transferToMainRests, isPending } = useTransferToMainRestsMutation(
		undefined,
		handleIsModalOpen
	);

	const spotCurrencies = useAppSelector(selectSpotAccountsRests);
	const commissionCurrencies = useAppSelector(selectCommissionAccountRests);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleSubmit = () => {
		const transferParams: TTransferParams[] = [];
		totalAccountsRests.forEach((accountInfo) => {
			const transferObject: TTransferParams = {
				accNumFrom: accountInfo.accNum,
				assets: [],
			};
			accountInfo.assets.forEach((asset) => {
				if (asset.code === assetCode) {
					transferObject.assets.push(asset.code);
				}
			});
			if (transferObject?.assets.length > 0) {
				transferParams.push(transferObject);
			}
		});

		transferToMainRests({
			accessToken: accessToken as string,
			navigate: navigate,
			accNumTo: mainEWallet,
			transferParams: transferParams,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletConvertComponent'}>
					<div className={'modalWalletSearchHeader'}>
						<h1 className={'confirmEmailText'}>
							{t('wallet_transferToWallet')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<>
						<div className="modalWalletSearchContent">
							<h1 className="walletConvertModalReceivedContainerHeader">
								{t('asset_transferwarn').replace(
									'{amount}',
									String(
										(spotCurrencies?.assets.find(
											(asset) => asset.code === assetCode
										)?.available ?? 0) +
											(commissionCurrencies?.assets.find(
												(asset) => asset.code === assetCode
											)?.available ?? 0)
									) + ` ${assetCode}`
								)}
							</h1>
						</div>
						<div className="modalLogoutOuterContainer">
							<CustomButton
								isGrey
								text={t('asset_transferConfirm') as string}
								isLoading={isPending}
								onClick={handleSubmit}
								className="transferButton"
							/>
							<CustomButton
								text={t('general_cancel') as string}
								onClick={handleModalClose}
								className="transferButton"
							/>
						</div>
					</>
				</div>
			</Fade>
		</Modal>
	);
};

interface WalletTransferRestsToMainModalProps {
	globalAsset: TWalletModalItem;
	handleIsModalOpen: (isLeverageTransfer?: boolean) => void;
	isModalOpen: boolean;
}

const WalletTransferRestsToMainModal = ({
	globalAsset,
	isModalOpen,
	handleIsModalOpen,
}: WalletTransferRestsToMainModalProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const walletTransferAssets = useAppSelector(selectWalletTransferToMainRestsAssets);
	const isLeverageTransfer = useAppSelector(selectIsLeverageTransfer);
	const accessToken = useAppSelector(selectAccessToken);
	const baseLowBalance = useAppSelector(selectBaseLowBalance);
	const mainEWallet = useAppSelector(selectMainEWallet);
	const totalAccountsRests = useAppSelector(selectTotalAccountsRests);

	const [isSummary, setIsSummary] = useState<boolean>(false);

	const { transferToMainRests, isError, isPending, error } =
		useTransferToMainRestsMutation(setIsSummary);

	const handleSelectedAmountChange = (i: TAsset, isSelected: boolean, idx: number) => {
		dispatch(
			selectTransferOne({
				idx: idx,
				checked: isSelected,
			})
		);
	};

	const handleModalClose = () => {
		dispatch(clearTransferSelected());
		setIsSummary(false);
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleAllSelectedClick = (isSelectedEventValue: boolean) => {
		dispatch(selectTransferAll(isSelectedEventValue));
	};

	const handleSubmit = () => {
		const transferParams: TTransferParams[] = [];
		totalAccountsRests.forEach((accountInfo) => {
			const transferObject: TTransferParams = {
				accNumFrom: accountInfo.accNum,
				assets: [],
			};
			accountInfo.assets.forEach((asset) => {
				walletTransferAssets.assets.forEach((selectedAsset, idx) => {
					if (
						asset.code === selectedAsset.code &&
						walletTransferAssets.selected[idx]
					) {
						transferObject.assets.push(asset.code);
					}
				});
			});
			if (transferObject?.assets.length > 0) {
				transferParams.push(transferObject);
			}
		});

		transferToMainRests({
			accessToken: accessToken as string,
			navigate: navigate,
			accNumTo: mainEWallet,
			transferParams: transferParams,
		});
	};

	if (isLeverageTransfer) {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div className={'modalWalletConvertComponent'}>
						<div className={'modalWalletSearchHeader'}>
							<h1 className={'confirmEmailText'}>
								{t('wallet_usingLeverageTitle')}
							</h1>
							<CustomButton
								isClose={true}
								onClick={handleModalClose}
								className={'registrationCloseButtonContainer'}
							>
								<CloseIcon
									sx={{
										fill: 'black',
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
						<div
							style={{
								gap: '16px',
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
							}}
							className={'modalWalletSearchContent'}
						>
							<h1 className="modalWalletEmptyText">
								{t('wallet_usingLeverageInfo')}
							</h1>
							<CustomButton
								className="confirmFullWidthButton"
								onClick={() => window.open(COMMON_ROUTES.LK)}
								text={t('wallet_openWebVersion') as string}
							/>
						</div>
					</div>
				</Fade>
			</Modal>
		);
	}

	if (isSummary) {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div className={'modalWalletConvertComponent'}>
						<WhaleIcon className="whaleConvertIcon" />
						<h1 className={'modalWalletConvertSummaryHeader'}>
							{t('wallet_transferProcessedTitle')}
						</h1>
						{isError ? (
							<APIError isError={isError} error={error} />
						) : (
							<h1 className={'modalWalletConvertSummaryBottom'}>
								{t('operation_conversionProcessedDetails')}
							</h1>
						)}

						<CustomButton
							className="closeFullWidthButton"
							isGrey
							text={t('notifications_close') as string}
							onClick={handleModalClose}
						/>
					</div>
				</Fade>
			</Modal>
		);
	}

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletConvertComponent'}>
					<div className={'modalWalletSearchHeader'}>
						<h1 className={'confirmEmailText'}>
							{t('wallet_transferToWallet')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					{walletTransferAssets.assets.length === 0 ? (
						<div className={'modalWalletSearchContent'}>
							<h1 className="modalWalletEmptyText">
								{t('wallet_youDontHaveAnyAssets')
									.replaceAll('{baseAssetCode}', globalAsset.code)
									.replaceAll(
										'{baseLowBalance}',
										baseLowBalance.toString()
									)}
							</h1>
							<CustomButton
								className=""
								onClick={handleModalClose}
								text={t('wallet_makeFirstDeposit') as string}
							/>
						</div>
					) : (
						<>
							<div className="modalWalletSearchContent">
								<div className="modalWalletConvertSelectAllContainer">
									<CustomCheckbox
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => handleAllSelectedClick(e.target.checked)}
									/>
									<h1 className="modalWalletConvertSelectAllText">
										{t('wallet_selectAllAssets')}
									</h1>
								</div>
								<WalletItemList
									isBalanceHidden={false}
									globalAsset={globalAsset}
									isLowBalanceHidden={false}
									isWalletTransferModal
									selected={walletTransferAssets.selected}
									handleSelectedAmountChange={
										handleSelectedAmountChange
									}
								/>
							</div>
							<div className="walletTransferModalBottomContainer">
								<CustomButton
									isDisabled={
										!walletTransferAssets.selected.find(
											(isSelected) => isSelected === true
										)
									}
									text={t('wallet_transfer') as string}
									isLoading={isPending}
									onClick={handleSubmit}
									className="transferButton"
								/>
							</div>
						</>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

interface CryptoDepositNetworkModalProps extends BaseModalProps {
	handleItemClick: (i: TInnerCryptoDeposit | TInnerCryptoWithdraw) => void;
	currentItem: TInnerCryptoDeposit | TInnerCryptoWithdraw | undefined;
	suitableNetworks: TInnerCryptoDeposit[] | TInnerCryptoWithdraw[] | undefined;
}

const CryptoDepositNetworkModal = ({
	handleIsModalOpen,
	isModalOpen,
	handleItemClick,
	currentItem,
	suitableNetworks,
}: CryptoDepositNetworkModalProps) => {
	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { t } = useTranslation();

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('general_network')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{suitableNetworks?.map((network) => (
							<div
								className="networkItemContainer"
								onClick={() => handleItemClick(network)}
							>
								<div className="networkItemInnerTextContainer">
									<h1 className="networkItemInnerTextHeader">
										{network?.networkCode}
									</h1>
									<h1 className="networkItemInnerTextBottom">
										{network?.networkName}
									</h1>
								</div>
								{currentItem?.networkName === network?.network && (
									<div className="networkSelectIconContainer">
										<OperationStatusIcon status="done" />
									</div>
								)}
							</div>
						))}
						<CustomButton
							className="confirmFullWidthButton"
							onClick={handleModalClose}
							text={t('general_chooseNetwork') as string}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CryptoWithdrawAssetModalProps extends BaseModalProps {
	handleItemClick: (i: TInnerCryptoWithdrawAsset, networkCode: string) => void;
}

const CryptoWithdrawAssetModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: CryptoWithdrawAssetModalProps) => {
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw?.crypto;
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketAssets = useAppSelector(selectMarketAssets)?.all;
	const mainCurrencies = useAppSelector(selectMainAccountRests);

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	const [searchValue, setSearchValue] = useState<string>('');

	const [selectAssets, setSelectAssets] = useState<TInnerCryptoWithdraw[]>([]);

	const [mutatedSelectAssets, setMutatedSelectAssets] = useState<
		TInnerCryptoWithdraw[]
	>([]);

	useEffect(() => {
		if (depositWithdrawAssetsDictionary && isModalOpen) {
			if (searchValue != '') {
				setSelectAssets(
					mutatedSelectAssets?.map((network) => {
						return {
							assets: network?.assets?.filter(
								(asset) =>
									asset?.code
										?.toLowerCase()
										?.includes(searchValue?.toLowerCase()) ||
									//eslint-disable-next-line
									(asset as any)?.name
										?.toLowerCase()
										?.includes(searchValue?.toLowerCase())
							),
							network: network.network,
							networkCode: network.networkCode,
							networkIcon: network.networkIcon,
							networkName: network.networkName,
							minConfirm: network.minConfirm,
						};
					})
				);
			} else {
				if (depositWithdrawAssetsDictionary && marketAssets) {
					setMutatedSelectAssets(
						depositWithdrawAssetsDictionary?.map((network) => {
							return {
								assets: network?.assets?.map((asset) => {
									return {
										code: asset?.code,
										name: marketAssets?.find(
											(marketAsset) =>
												marketAsset?.code === asset?.code
										)?.name,
										withdrawFee: asset?.withdrawFee,
										minWithdraw: asset?.minWithdraw,
									};
								}),
								network: network.network,
								networkCode: network.networkCode,
								networkIcon: network.networkIcon,
								networkName: network.networkName,
								minConfirm: network.minConfirm,
							};
						})
					);
					setSelectAssets(depositWithdrawAssetsDictionary);
				}
			}
		}
	}, [searchValue, depositWithdrawAssetsDictionary, marketAssets, isModalOpen]);

	const handleModalClose = () => {
		handleIsModalOpen();
		setSearchValue('');
		setSelectAssets([]);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleClearClick = () => {
		setSearchValue('');
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { t } = useTranslation();

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('withdrawCrypto_withdrawAsset')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<CustomInput
							placeHolder={t('general_searchTitle') as string}
							className={'modalSelectDepositAssetSearchInput'}
							id={'cryptoWithdrawSelectModalSearchInput'}
							label=""
							value={searchValue}
							isRequired={false}
							onClearClick={handleClearClick}
							type={'text'}
							onChange={handleInputChange}
							isSearch
						/>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								{mainCurrencies?.assets &&
								mainCurrencies?.assets?.length > 0 &&
								marketAssets &&
								marketAssets?.length > 0 ? (
									<>
										{selectAssets?.map((network, idx) => (
											<>
												{network?.assets
													?.filter(
														(asset) =>
															asset?.code ===
															mainCurrencies?.assets?.find(
																(mainAsset) =>
																	mainAsset?.code ===
																	asset?.code
															)?.code
													)
													?.map((asset, secondIdx) => (
														<WalletItem
															basePrecision={
																mainCurrencies?.basePrecision
															}
															isBalanceHidden={false}
															baseAsset={globalAsset.code}
															key={idx + secondIdx}
															idx={idx + secondIdx}
															asset={mainCurrencies?.assets?.find(
																(mainCurrency) =>
																	mainCurrency?.code ===
																	asset?.code
															)}
															withdrawAsset={asset}
															networkCode={
																network?.networkCode
															}
															handleItemClick={
																handleItemClick
															}
														/>
													))}
											</>
										))}
									</>
								) : (
									<>
										{loadingCards.map((card) => (
											<MarketAssetItem
												isLoading
												key={card}
												idx={Number(card)}
											/>
										))}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CryptoDepositAssetModalProps extends BaseModalProps {
	handleItemClick: (i: TInnerCryptoDepositAsset, networkCode: string) => void;
}

const CryptoDepositAssetModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: CryptoDepositAssetModalProps) => {
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.deposit?.crypto;
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketAssets = useAppSelector(selectMarketAssets)?.all;

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	const [searchValue, setSearchValue] = useState<string>('');

	const [selectAssets, setSelectAssets] = useState<TInnerCryptoDeposit[]>([]);

	const [mutatedSelectAssets, setMutatedSelectAssets] = useState<TInnerCryptoDeposit[]>(
		[]
	);

	useEffect(() => {
		if (depositWithdrawAssetsDictionary && isModalOpen) {
			if (searchValue != '') {
				setSelectAssets(
					mutatedSelectAssets?.map((network) => {
						return {
							assets: network?.assets?.filter(
								(asset) =>
									asset?.code
										?.toLowerCase()
										?.includes(searchValue?.toLowerCase()) ||
									//eslint-disable-next-line
									(asset as any)?.name
										?.toLowerCase()
										?.includes(searchValue?.toLowerCase())
							),
							network: network.network,
							networkCode: network.networkCode,
							networkIcon: network.networkIcon,
							networkName: network.networkName,
							minConfirm: network.minConfirm,
						};
					})
				);
			} else {
				if (depositWithdrawAssetsDictionary && marketAssets) {
					setMutatedSelectAssets(
						depositWithdrawAssetsDictionary?.map((network) => {
							return {
								assets: network?.assets?.map((asset) => {
									return {
										code: asset?.code,
										name:
											marketAssets?.find(
												(marketAsset) =>
													marketAsset?.code === asset?.code
											) != undefined
												? marketAssets?.find(
														(marketAsset) =>
															marketAsset?.code ===
															asset?.code
												  )?.name
												: asset?.code,
										depositFee: asset?.depositFee,
										minDeposit: asset?.minDeposit,
									};
								}),
								network: network.network,
								networkCode: network.networkCode,
								networkIcon: network.networkIcon,
								networkName: network.networkName,
								minConfirm: network.minConfirm,
							};
						})
					);
					setSelectAssets(depositWithdrawAssetsDictionary);
				}
			}
		}
	}, [searchValue, depositWithdrawAssetsDictionary, marketAssets, isModalOpen]);

	const handleModalClose = () => {
		handleIsModalOpen();
		setSearchValue('');
		setSelectAssets([]);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleClearClick = () => {
		setSearchValue('');
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { t } = useTranslation();

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('depositCrypto_depositAsset')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<CustomInput
							placeHolder={t('general_searchTitle') as string}
							className={'modalSelectDepositAssetSearchInput'}
							id={'cryptoDepositSelectModalSearchInput'}
							label=""
							value={searchValue}
							isRequired={false}
							onClearClick={handleClearClick}
							type={'text'}
							onChange={handleInputChange}
							isSearch
						/>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								{marketAssets && marketAssets?.length > 0 ? (
									<>
										{selectAssets?.map((network, idx) => (
											<>
												{network?.assets?.map(
													(asset, secondIdx) => (
														<MarketAssetItem
															isDeposit
															onClick={handleItemClick}
															timeFrame={`24${t(
																'filters_h'
															)}`}
															globalAssetCode={
																globalAsset?.code
															}
															key={asset.code}
															idx={idx + secondIdx}
															asset={marketAssets.find(
																(marketAsset) =>
																	marketAsset?.code ===
																	asset?.code
															)}
															depositAsset={asset}
															networkCode={
																network?.networkCode
															}
														/>
													)
												)}
											</>
										))}
									</>
								) : (
									<>
										{loadingCards.map((card) => (
											<MarketAssetItem
												isLoading
												key={card}
												idx={Number(card)}
											/>
										))}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CardDepositAssetModalProps extends WalletModalProps {
	isBankDeposit?: boolean;
	currentBankCountry?: TBankCountry;
}

const CardDepositAssetModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	isBankDeposit,
	currentBankCountry,
}: CardDepositAssetModalProps) => {
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	);
	const banksDictionary = useAppSelector(selectBanksDictionary);
	const { t } = useTranslation();

	function filterAssetsByCurrent() {
		if (isBankDeposit) {
			return banksDictionary?.dictionary?.deposit?.countries.find(
				(country) => country?.countryCode === currentBankCountry?.countryCode
			)?.currencies;
		}
		return depositWithdrawAssetsDictionary?.dictionary?.deposit?.fiat[1]?.card;
	}

	const localAppSettingsItems = useMemo(() => filterAssetsByCurrent(), [isModalOpen]);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('general_chooseCurrency')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{localAppSettingsItems &&
							localAppSettingsItems?.map((accountAsset, idx) => (
								<WalletModalItem
									asset={{
										code: accountAsset.code,
										//eslint-disable-next-line
										name: (accountAsset as any)?.name
											? //eslint-disable-next-line
											  (accountAsset as any)?.name
											: accountAsset.code,
									}}
									key={idx}
									idx={idx}
									onClick={handleItemClick}
								/>
							))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface BankTransferDepositBankModalProps extends BaseModalProps {
	handleItemClick: (i: TInnerBank) => void;
	banks: TBankCountry | undefined;
	currentItem: TInnerBank | undefined;
	currentAsset: TWalletModalItem | undefined;
}

const BankTransferDepositBankModal = ({
	handleIsModalOpen,
	handleItemClick,
	isModalOpen,
	banks,
	currentItem,
	currentAsset,
}: BankTransferDepositBankModalProps) => {
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const [mutatedBanks, setMutatedBanks] = useState<TInnerBank[] | undefined>([]);

	useEffect(() => {
		setMutatedBanks(
			banks?.banks?.filter((bank) =>
				banks?.currencies
					.find((currency) => currency?.code === currentAsset?.code)
					?.banks.find((currencyBankKey) => currencyBankKey === bank?.bankKey)
			)
		);
	}, [currentAsset]);

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('general_bankTransfer')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{mutatedBanks?.length === 0 ? (
							<div
								style={{
									width: '100%',
								}}
								className="walletConfirmIdentityContainer"
							>
								<WhaleIcon className="walletConfirmIdentityWhale" />
								<h1 className="walletConfirmIdentityHeader">
									{t('general_noResultsFound')}
								</h1>
							</div>
						) : (
							mutatedBanks?.map((bank, idx) => (
								<div
									key={idx}
									className="networkItemContainer"
									onClick={() => handleItemClick(bank)}
								>
									<div className="networkItemInnerTextContainer">
										<h1 className="networkItemInnerTextHeader">
											{bank?.bankName}
										</h1>
									</div>
									{currentItem?.bankName === bank?.bankName && (
										<div className="networkSelectIconContainer">
											<OperationStatusIcon status="done" />
										</div>
									)}
								</div>
							))
						)}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface BankTransferDepositCountryModalProps extends BaseModalProps {
	handleItemClick: (i: TBankCountry) => void;
}

const BankTransferDepositCountryModal = ({
	handleIsModalOpen,
	isModalOpen,
	handleItemClick,
}: BankTransferDepositCountryModalProps) => {
	const banksDictionary = useAppSelector(selectBanksDictionary);
	const countryDictionary = useAppSelector(selectCountriesDictionary);
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('savedAddresses_chooseCountry')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{banksDictionary &&
							banksDictionary?.dictionary?.deposit?.countries?.map(
								(country, idx) => (
									<div
										onClick={() => handleItemClick(country)}
										key={idx}
										className="assetItem"
									>
										<img
											className="assetIcon"
											src={
												countryDictionary?.dictionary?.find(
													(localCountry: TCountry) =>
														localCountry?.code ===
														country?.countryCode
												)?.link
											}
											alt={country?.countryCode}
										/>
										<div className="innerAssetItemHeader">
											<h1 className="innerAssetHeaderText">
												{country?.countryCode}
											</h1>
											<h1 className="innerAssetBottomText">
												{country?.countryName}
											</h1>
										</div>
									</div>
								)
							)}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface WalletModalProps extends BaseModalProps {
	handleItemClick: (i: TWalletModalItem) => void;
	currentAsset: TWalletModalItem;
}

const WalletModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	currentAsset,
}: WalletModalProps) => {
	const appSettings = useAppSelector(selectAppSettings);
	const { t } = useTranslation();

	function filterAssetsByCurrent(currentAsset: TWalletModalItem) {
		return appSettings?.accountAssets.filter(
			(asset) => asset.code != currentAsset.code
		);
	}

	const localAppSettingsItems = useMemo(
		() => filterAssetsByCurrent(currentAsset),
		[currentAsset, isModalOpen]
	);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('settings_resultingAsset')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{localAppSettingsItems &&
							localAppSettingsItems?.map((accountAsset, idx) => (
								<WalletModalItem
									asset={accountAsset}
									key={idx}
									idx={idx}
									onClick={handleItemClick}
								/>
							))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const LogoutModal = ({ handleIsModalOpen, isModalOpen }: BaseModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { logout, isPending } = useRevokeTokenMutation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleLogoutClick = () => {
		logout({
			dispatch: dispatch,
			accessToken: accessToken as string,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('settings_logOut')}?</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalLogoutOuterContainer'}>
						<h1 className="modalWalletEmptyText">
							{t('settings_logOutSubtitle')}
						</h1>
						<CustomButton
							isGrey
							text={t('settings_logOut') as string}
							type={'submit'}
							onClick={handleLogoutClick}
							isLoading={isPending}
							className={'confirmFullWidthButton'}
						/>
						<CustomButton
							text={t('general_cancel') as string}
							type={'submit'}
							onClick={handleIsModalOpen}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const CardWarningModal = ({ handleIsModalOpen, isModalOpen }: BaseModalProps) => {
	const { t } = useTranslation();
	const appSettings = useAppSelector(selectAppSettings);
	const navigate = useCustomNavigate();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('depositCard_bottomsheetInfoTitle')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalLogoutOuterContainer'}>
						<h1 className="modalWalletEmptyText">
							{appSettings?.depositWithdrawInformation?.bottomsheetText}
						</h1>
						<CustomButton
							text={t('depositCard_depositButtonText') as string}
							type={'submit'}
							onClick={() => navigate(ROUTES.DEPOSIT.CARD)}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface GenerateAccountConfirmationModalProps extends BaseModalProps {
	locale: string;
	handleLocaleModalOpen: () => void;
}

const GenerateAccountConfirmationModal = ({
	handleIsModalOpen,
	isModalOpen,
	locale,
	handleLocaleModalOpen,
}: GenerateAccountConfirmationModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);
	const isVerified = useAppSelector(selectIsVerified);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { isPending, send } = useSendGeneralAccountLetterMutation(handleModalClose);

	const handleSubmit = () => {
		send({
			accessToken: accessToken as string,
			locale: locale,
			dispatch: dispatch,
		});
	};

	if (!isVerified) {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div className={'modalWalletGlobalAssetComponent'}>
						<div className="modalComponentHeaderEndPlacement">
							<CustomButton
								isClose={true}
								onClick={handleModalClose}
								className={'registrationCloseButtonContainer'}
							>
								<CloseIcon
									sx={{
										fill: 'black',
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
						<div className={'modalGenerateAccountStatementContent'}>
							<VerificationStarIcon />
							<h1 className="modalGenerateAccountStatementUnverifiedTextHeader">
								{t('verification_bottomsheetTitle')}
							</h1>
							<h1 className="modalGenerateAccountStatementUnverifiedTextBottom">
								{t('verification_bottomsheetSubtitle')}
							</h1>
							<CustomButton
								text={t('verification_bottomsheetButton') as string}
								type={'submit'}
								onClick={() => console.log('WIP')}
								className={'confirmFullWidthButton'}
								isDisabled={false}
							/>
						</div>
					</div>
				</Fade>
			</Modal>
		);
	}

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('statement_confirmationLetter')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						className={
							'modalContent modalLocaleContent modalGenerateStatement'
						}
					>
						<div className="commonContainer">
							<div onClick={handleLocaleModalOpen} className="commonItem">
								<h1 className="commonText">{t('general_language')}</h1>
								<h1 className="innerCommonText">
									{t(
										`general_${
											locale === 'en' ? 'english' : 'russian'
										}`
									)}
								</h1>
								<RightChev />
							</div>
						</div>
						<CustomButton
							isLoading={isPending}
							text={t('statement_open') as string}
							type={'submit'}
							onClick={handleSubmit}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const CryptoWithdrawEditSavedAddressModal = ({
	isModalOpen,
	handleIsModalOpen,
}: BaseModalProps) => {
	const selectedAddress = useAppSelector(selectCryptoWithdrawCurrentSavedAddress);
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [templateName, setTemplateName] = useState<string>(
		selectedAddress?.templateName as string
	);
	const [isTemplateNameRequired, setIsTemplateNameRequired] = useState<boolean>(false);

	useEffect(() => {
		if (selectedAddress) {
			setTemplateName(selectedAddress?.templateName);
		}
	}, [selectedAddress]);

	const {
		isDeletePending,
		isDeleteError,
		deleteError,
		deleteCryptoWithdrawSavedAddresses,
	} = useDeleteCryptoWithdrawSavedAddresses(
		dispatch,
		selectedAddress?.templateId as string,
		handleIsModalOpen
	);
	const {
		isUpdatePending,
		isUpdateError,
		updateError,
		updateCryptoWithdrawSavedAddresses,
	} = useUpdateCryptoWithdrawSavedAddresses(
		dispatch,
		selectedAddress?.templateName as string,
		selectedAddress?.templateId as string,
		handleIsModalOpen
	);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTemplateName(e?.target?.value);
		setIsTemplateNameRequired(e?.target?.value?.length > 0);
	};

	const handleClearClick = () => {
		setTemplateName('');
		setIsTemplateNameRequired(false);
	};

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleUpdateClick = () => {
		updateCryptoWithdrawSavedAddresses({
			accessToken: accessToken as string,
			dispatch: dispatch,
			templateId: selectedAddress?.templateId as string,
			templateName: templateName,
			networkName: selectedAddress?.networkName as string,
		});
	};

	const handleDeleteClick = () => {
		deleteCryptoWithdrawSavedAddresses({
			accessToken: accessToken as string,
			dispatch: dispatch,
			templateId: selectedAddress?.templateId as string,
			networkName: selectedAddress?.networkName as string,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('savedAddresses_editSavedAddress')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div
								style={{
									gap: '24px',
								}}
								className="depositAssetSelectScrollContainer"
							>
								<CustomInput
									isRequired={isTemplateNameRequired}
									type="text"
									id="cryptoWithdrawTemplateName"
									value={templateName}
									onChange={handleInputChange}
									onClearClick={handleClearClick}
									className="confirmFullWidthButton"
									label={t('savedAddresses_addressName') as string}
								/>
								<CustomInput
									isRequired
									isSelectAsset
									isDisabled
									type="text"
									id="cryptoWithdrawSelectNetwork"
									value={
										(selectedAddress?.networkCode as string) +
										' ' +
										selectedAddress?.networkName
									}
									imgUrl={''}
									className="confirmFullWidthButton"
									label={t('general_network') as string}
								/>
								<CustomInput
									isNonCloseIcon
									isDisabled
									label={t('withdrawCrypto_cryptoAddress') as string}
									className="confirmFullWidthButton"
									type="text"
									id="cryptoWithdrawAddress"
									isRequired={true}
									value={selectedAddress?.cryptoAddress as string}
								/>
								<APIError
									isError={isDeleteError || isUpdateError}
									error={deleteError || updateError}
								/>
								<CustomButton
									isLoading={isUpdatePending}
									onClick={handleUpdateClick}
									className="confirmFullWidthButton"
									text={t('withdrawResult_saveAddress') as string}
								/>
								<CustomButton
									isLoading={isDeletePending}
									isGrey
									onClick={handleDeleteClick}
									className="confirmFullWidthButton"
									text={t('savedAddresses_removeAddress') as string}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CryptoWithdrawSavedAddressModalProps extends BaseModalProps {
	handleItemClick: (i: string) => void;
	handleEditSavedAddressModalOpen: (i: TSavedAddress | undefined) => void;
}

const CryptoWithdrawSavedAddressModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	handleEditSavedAddressModalOpen,
}: CryptoWithdrawSavedAddressModalProps) => {
	const selectedNetwork = useAppSelector(selectCryptoWithdrawNetwork);
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isPending, getCryptoWithdrawSavedAddresses } =
		useGetCryptoWithdrawSavedAddresses(dispatch);

	useEffect(() => {
		if (isModalOpen && selectedNetwork != undefined) {
			getCryptoWithdrawSavedAddresses({
				accessToken: accessToken as string,
				dispatch: dispatch,
				networkName: selectedNetwork?.networkName as string,
			});
		}
	}, [isModalOpen, selectedNetwork]);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('savedAddresses_title')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								<SavedAddressItemList
									handleItemClick={handleItemClick}
									isLoading={isPending}
									handleModalOpen={handleEditSavedAddressModalOpen}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CryptoWithdrawHistoryModalProps extends BaseModalProps {
	handleItemClick: (i: TOperation) => void;
}

const CryptoWithdrawHistoryModal = ({
	handleIsModalOpen,
	handleItemClick,
	isModalOpen,
}: CryptoWithdrawHistoryModalProps) => {
	const { t } = useTranslation();

	const { isLoading, getOperationsByTabChange } = useGetDefaultHistory();

	useEffect(() => {
		if (isModalOpen) {
			getOperationsByTabChange('withdraw');
		}
	}, [isModalOpen]);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('withdrawCrypto_withdrawHistory')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								<HistoryItemList
									handleItemClick={handleItemClick}
									isLoading={isLoading}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface HistoryOperationDetailsModalProps extends BaseModalProps {
	handleRejectOperation: (operId?: string) => void;
}

const HistoryOperationDetailsModal = ({
	handleIsModalOpen,
	isModalOpen,
	handleRejectOperation,
}: HistoryOperationDetailsModalProps) => {
	const dispatch = useAppDispatch();
	const operation = useAppSelector(selectCurrentOperation);
	const operationDetails = useAppSelector(selectOperationDetails);

	const { t } = useTranslation();

	const handleModalClose = () => {
		dispatch(setOperationDetails(undefined));
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const loadingCards = ['1', '2', '3', '4'];

	if (!operationDetails) {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div className={'modalOperationDetailsComponentGrey'}>
						<div className="modalComponentHeader">
							<h1 className={'confirmEmailTextLoading'} />
							<CustomButton
								isClose={true}
								onClick={handleModalClose}
								className={'statementModalGreyContainer'}
							>
								<CloseIcon
									sx={{
										fill: 'black',
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
						<div className={'modalOperationDetailsContent'}>
							<div className="commonOperationDetailsContainer">
								<div className="commonOperationDetailsItem">
									<h1 className="innerCommonTextLoading" />
									<h1 className="commonTextLoading" />
								</div>
							</div>

							<div className="commonOperationDetailsContainer">
								{loadingCards?.map((card) => (
									<div
										key={card}
										className="commonOperationDetailsItem"
									>
										<h1 className="innerCommonTextLoading" />
										<h1 className="commonTextLoading" />
									</div>
								))}
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		);
	}

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalOperationDetailsComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{operationDetails?.operationType}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalOperationDetailsContent'}>
						<div className="commonOperationDetailsContainer">
							<div className="commonOperationDetailsItem">
								<h1 className="keyOperationDetailsText">
									{operationDetails?.status?.paramName}
								</h1>
								<h1 className="valueOperationDetailsText">
									<OperationStatusIcon
										status={
											operationDetails?.status
												?.paramStatus as string
										}
										className="operationDetailsIcon"
									/>
									{operationDetails?.status?.paramValue}
								</h1>
							</div>
						</div>
						{operationDetails?.blocks?.map((block) => (
							<div className="commonOperationDetailsContainer">
								{block?.block?.map((innerBlock) => (
									<>
										<div className="commonOperationDetailsItem">
											<h1 className="keyOperationDetailsText">
												{innerBlock?.paramName}
											</h1>
											<h1 className="valueOperationDetailsText">
												{innerBlock?.paramValue}
											</h1>
										</div>
										{innerBlock?.paramCopy && (
											<div className="copyGreenContainer">
												<CopyGreenIcon className="copyGreenIcon" />
												<h1 className="copyGreenText">
													{innerBlock?.paramCopy}
												</h1>
											</div>
										)}
									</>
								))}
							</div>
						))}
					</div>
					{operationDetails?.isCancelable === 1 && (
						<CustomButton
							onClick={() => handleRejectOperation(operation?.operaionid)}
							className="confirmFullWidthButton"
							isText
							text={t('operation_cancelOperationTitle') as string}
						/>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

interface BankDepositInvoiceHistoryModalProps extends BaseModalProps {
	isLoading: boolean;
}

const BankDepositInvoiceHistoryModal = ({
	isLoading,
	isModalOpen,
	handleIsModalOpen,
}: BankDepositInvoiceHistoryModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);
	const invoiceId = useAppSelector(selectCurrentInvoiceId);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const { deleteBankDepositInvoice, isError, error, isPending } =
		useDeleteBankDepositInvoice(dispatch, handleModalClose);

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleDeleteInvoice = () => {
		deleteBankDepositInvoice({
			accessToken: accessToken as string,
			dispatch: dispatch,
			invoiceId: invoiceId,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('invoice_title')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						className={
							'modalContent modalLocaleContent modalGenerateStatement'
						}
					>
						<div
							style={{
								width: '100%',
							}}
							className="modalAssetDepositSelectContent"
						>
							<div
								style={{
									gap: '16px',
								}}
								className="depositAssetSelectScrollContainer"
							>
								<BankTransferInvoicePreviewLayout isLoading={isLoading} />
								<APIError isError={isError} error={error} />
								<CustomButton
									isDisabled={isLoading}
									isLoading={isPending}
									isGrey
									text={t('invoice_delete') as string}
									className="confirmFullWidthButton"
									onClick={handleDeleteInvoice}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const BankTransferDepositDetailsModal = ({
	handleIsModalOpen,
	isModalOpen,
}: BaseModalProps) => {
	const invoiceInfo = useAppSelector(selectInvoiceInfo);

	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleCopyClick = (copyValue: string) => {
		navigator.clipboard.writeText(copyValue);
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('invoice_bankCredentials')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={
							'modalContent modalLocaleContent modalGenerateStatement'
						}
					>
						<div
							style={{
								width: '100%',
							}}
							className="modalAssetDepositSelectContent"
						>
							<div
								style={{
									gap: '16px',
								}}
								className="depositAssetSelectScrollContainer"
							>
								{invoiceInfo?.map((invoice) => (
									<>
										{invoice?.isVisible && (
											<div className="commonContainer">
												<div className="invoiceDetailsContainer">
													<h1 className="invoiceDetailsHeaderText">
														{invoice?.paramName}
													</h1>
													<div className="invoiceDetailsInnerContainer">
														<h1 className="invoiceDetailsBottomText">
															{invoice?.paramValue}
														</h1>
														<CopyIcon
															className=""
															onClick={() =>
																handleCopyClick(
																	invoice?.paramValue
																)
															}
														/>
													</div>
												</div>
											</div>
										)}
									</>
								))}
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface HistoryPeriodModalProps extends BaseModalProps {
	handleCustomPeriodModalOpen: () => void;
	handleItemClick: (i: THistoryPeriod) => void;
	handleResetClick: () => void;
	currentTimeFrame: THistoryPeriod | undefined;
}

const HistoryPeriodModal = ({
	isModalOpen,
	handleCustomPeriodModalOpen,
	handleIsModalOpen,
	handleItemClick,
	handleResetClick,
	currentTimeFrame,
}: HistoryPeriodModalProps) => {
	const { t } = useTranslation();
	const historyPeriods = useAppSelector(selectPeriodFiltersDictionary).dictionary
		?.historyperiod;

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('history_period')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						className={
							'modalContent modalLocaleContent modalGenerateStatement'
						}
					>
						<div className="commonContainer">
							{historyPeriods?.map((period, idx) => (
								<HistoryPeriodModalItem
									item={period}
									handleItemClick={handleItemClick}
									idx={idx}
									isActive={
										period?.datefilter ===
										currentTimeFrame?.datefilter
									}
								/>
							))}
							<HistoryPeriodModalItem
								idx={(historyPeriods?.length as number) + 1}
								isCustom
								name={t('calendar_customPeriod') as string}
								handleCustomPeriodModalOpen={handleCustomPeriodModalOpen}
								datePeriod={
									currentTimeFrame?.datefilter === 'period'
										? currentTimeFrame.shortName
										: ''
								}
							/>
						</div>
						<CustomButton
							isGrey
							isLoading={false}
							text={t('history_reset') as string}
							type={'submit'}
							onClick={handleResetClick}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface RejectOperationModalProps extends BaseModalProps {
	operId: string;
	additionalFunc?: () => void;
}

const RejectOperationModal = ({
	handleIsModalOpen,
	isModalOpen,
	operId,
	additionalFunc,
}: RejectOperationModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { isPending, reject } = useRejectOperation(handleModalClose, additionalFunc);

	const handleSubmit = () => {
		reject({
			accessToken: accessToken as string,
			operId: operId,
			dispatch: dispatch,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeaderEndPlacement">
						<h1 className={'confirmEmailText'}>
							{t('operation_cancelOperationTitle')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalGenerateAccountStatementContent'}>
						<h1 className="modalGenerateAccountStatementUnverifiedTextHeader">
							{t('operation_cancelOperationSubtitle')}
						</h1>
						<CustomButton
							text={t('operation_confirmCancellationTitle') as string}
							type={'submit'}
							isLoading={isPending}
							isGrey
							onClick={handleSubmit}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
						<CustomButton
							text={t('operation_dismissCancellationTitle') as string}
							type={'submit'}
							onClick={handleIsModalOpen}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface GenerateStatementModalModalProps extends BaseModalProps {
	locale: string;
	handleLocaleModalOpen: () => void;
}

const GenerateStatementModal = ({
	handleIsModalOpen,
	isModalOpen,
	locale,
	handleLocaleModalOpen,
}: GenerateStatementModalModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const [historyPeriod, setHistoryPeriod] = useState<THistoryPeriod | undefined>();
	const [datePeriod, setDatePeriod] = useState<THistoryDatePeriod | undefined>();
	const [dateNamePeriod, setDateNamePeriod] = useState<string>('');

	const historyPeriods = useAppSelector(selectPeriodFiltersDictionary).dictionary
		?.historyperiod;

	const [isHistoryCustomPeriodModalOpen, setIsHistoryCustomPeriodModalOpen] =
		useState<boolean>(false);

	const handleModalClose = () => {
		setDatePeriod(undefined);
		setDateNamePeriod('');
		setHistoryPeriod(undefined);
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleCustomPeriodModalOpen = () => {
		setIsHistoryCustomPeriodModalOpen(!isHistoryCustomPeriodModalOpen);
	};

	const handlePeriodItemClick = (i: THistoryPeriod) => {
		setHistoryPeriod(i);
		setDatePeriod(undefined);
		setDateNamePeriod('');
	};

	const { isPending, send } = useSendStatementMutation(handleModalClose);

	const handleSubmit = () => {
		send({
			accessToken: accessToken as string,
			locale: locale,
			dispatch: dispatch,
			period: historyPeriod ? (historyPeriod?.datefilter as string) : undefined,
			datePeriod: datePeriod ? datePeriod : undefined,
		});
	};

	const handleCustomPeriodSubmit = (e: THistoryDatePeriodWithDate) => {
		setHistoryPeriod(undefined);
		const datePeriod = {
			dateFrom: e.dateFrom?.format('DD/MM/YYYY').replaceAll('/', '') as string,
			dateTo: e.dateTo?.format('DD/MM/YYYY').replaceAll('/', '') as string,
			fullDateFrom: e.fullDateFrom,
			fullDateTo: e.fullDateTo,
		};
		setDatePeriod(datePeriod);
		setDateNamePeriod(
			e.dateFrom?.format('DD/MM').replaceAll('/', '.') +
				' - ' +
				e.dateTo?.format('DD/MM').replaceAll('/', '.')
		);
		handleCustomPeriodModalOpen();
	};

	if (isHistoryCustomPeriodModalOpen) {
		return (
			<HistoryCustomPeriodModal
				handleIsModalOpen={handleCustomPeriodModalOpen}
				isModalOpen={isHistoryCustomPeriodModalOpen}
				handleSubmit={handleCustomPeriodSubmit}
			/>
		);
	}

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('statement_title')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalLocaleContent modalGenerateStatement'}>
						<div className="commonContainer">
							<div onClick={handleLocaleModalOpen} className="commonItem">
								<h1 className="commonText">{t('general_language')}</h1>
								<h1 className="innerCommonText">
									{t(
										`general_${
											locale === 'en' ? 'english' : 'russian'
										}`
									)}
								</h1>
								<RightChev />
							</div>
						</div>

						<div className="commonContainer">
							{historyPeriods?.map((period, idx) => (
								<HistoryPeriodModalItem
									isActive={
										period.datefilter === historyPeriod?.datefilter
									}
									item={period}
									handleItemClick={handlePeriodItemClick}
									idx={idx}
								/>
							))}
							<HistoryPeriodModalItem
								idx={(historyPeriods?.length as number) + 1}
								isCustom
								name={t('calendar_customPeriod') as string}
								handleCustomPeriodModalOpen={handleCustomPeriodModalOpen}
								datePeriod={dateNamePeriod != '' ? dateNamePeriod : ''}
							/>
						</div>
						<CustomButton
							isLoading={isPending}
							text={t('statement_open') as string}
							type={'submit'}
							onClick={handleSubmit}
							className={'confirmFullWidthButton'}
							isDisabled={
								historyPeriod === undefined && datePeriod === undefined
							}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface HistoryCustomPeriodModalProps extends BaseModalProps {
	handleSubmit: (e: THistoryDatePeriodWithDate) => void;
}

const HistoryCustomPeriodModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleSubmit,
}: HistoryCustomPeriodModalProps) => {
	const locale = useAppSelector(selectLocale);
	const historyDatePeriod = useAppSelector(selectDatePeriod);
	const { t } = useTranslation();

	const [dateFrom, setDateFrom] = useState<Dayjs | null>(
		historyDatePeriod?.fullDateFrom != ''
			? dayjs(historyDatePeriod?.fullDateFrom)
			: null
	);
	const [dateTo, setDateTo] = useState<Dayjs | null>(
		historyDatePeriod?.fullDateTo != '' ? dayjs(historyDatePeriod?.fullDateTo) : null
	);
	const [isError, setIsError] = useState<boolean>(false);

	const handleModalClose = () => {
		setDateFrom(null);
		setDateTo(null);
		setIsError(false);
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleSubmitClick = () => {
		setDateFrom(null);
		setDateTo(null);
		setIsError(false);

		const datePeriod = {
			dateFrom: dateFrom as Dayjs,
			dateTo: dateTo as Dayjs,
			fullDateFrom: dateFrom?.toISOString() as string,
			fullDateTo: dateTo?.toISOString() as string,
		};

		handleSubmit(datePeriod);
	};

	const handleDateFromChange = (e: Dayjs | null) => {
		setIsError(false);
		setDateFrom(e);
	};

	const handleDateToChange = (e: Dayjs | null) => {
		setIsError(false);
		setDateTo(e);
	};

	useEffect(() => {
		if (dateFrom && dateTo) {
			setIsError(dateTo.isBefore(dateFrom));
		}
	}, [dateFrom, dateTo]);

	const handleTodayClick = () => {
		setDateTo(dayjs().locale(locale));
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('calendar_customPeriod')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalLocaleContent modalGenerateStatement'}>
						<CustomDatePicker
							value={dateFrom}
							isFrom
							onChange={handleDateFromChange}
							label={t('calendar_dateFrom')}
						/>

						<CustomDatePicker
							value={dateTo}
							onChange={handleDateToChange}
							label={t('calendar_dateTo')}
							handleTodayClick={handleTodayClick}
						/>

						<APIError
							isError={isError}
							error={{ message: t('calendar_dateToError') }}
						/>

						<CustomButton
							text={t('calendar_apply') as string}
							type={'submit'}
							onClick={handleSubmitClick}
							className={'confirmFullWidthButton'}
							isDisabled={dateFrom === null || dateTo === null || isError}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export {
	CryptoWithdrawEditSavedAddressModal,
	CryptoWithdrawSavedAddressModal,
	CryptoWithdrawHistoryModal,
	BankTransferDepositBankModal,
	BankTransferDepositCountryModal,
	CountryModal,
	OTPModal,
	ConfirmRegistrationModal,
	LocaleModal,
	WalletModal,
	WalletConvertModal,
	WalletTransferRestsToMainModal,
	MarketTimeFrameModal,
	ThemeModal,
	LogoutModal,
	GenerateAccountConfirmationModal,
	HistoryPeriodModal,
	AssetDetailsTransferModal,
	HistoryOperationDetailsModal,
	RejectOperationModal,
	GenerateStatementModal,
	CardWarningModal,
	HistoryCustomPeriodModal,
	CardDepositAssetModal,
	CryptoDepositAssetModal,
	CryptoDepositNetworkModal,
	BankTransferDepositDetailsModal,
	BankDepositInvoiceHistoryModal,
	CryptoWithdrawAssetModal,
};
