export const TITLES = {
	MAIN: {
		INDEX: 'Main',
	},
	PROFILE: {
		INDEX: 'Profile',
		SETTINGS: 'Settings | Profile',
		DOCUMENTS: 'My Documents | Profile',
		ACCOUNT_INFO: 'Account Info | Profile',
	},
	HISTORY: {
		INDEX: 'History',
	},
	ROOT: {
		INDEX: 'Wallet',
	},
	MAINTAIN: {
		INDEX: 'Maintain',
	},
	EXCHANGE: {
		INDEX: 'Exchange',
	},
	MARKET: {
		INDEX: 'Market',
		ASSET_DETAILS: 'Asset Details',
	},
	SEARCH: {
		INDEX: 'Search',
	},
	AUTH: {
		INDEX: 'Authentication',
		LOGIN: 'Login',
		TWOFA: 'TwoFA | Login',
		SELECT_TWOFA: 'Select TwoFa | Login',
		SETUP_BIOMETRY: 'Setup Biometry | Login',
		OTP: 'OTP | Login',
		SETUP: 'Setup TwoFA | Login',
		SECRET_KEY: 'Secret Key | Login',
		SENT: 'Sent | Login',
		IMPERSONATE: 'Impersonate | Login',
		RECOVERY: 'Recovery | Login',
		CONFIRM_EMAIL: 'Confirm Code | Login',
		NEW_PASSWORD: 'New Password | Login',
	},
	ABOUT: {
		INDEX: 'About',
		HELP: 'Help & Support',
		LEGAL_STATEMENTS: 'Legal Statements',
		COMMUNITY: 'Community',
	},
	DEPOSIT: {
		INDEX: 'Deposit',
		OPLATI: 'Oplati | Deposit',
		ERIP: 'ERIP | Deposit',
		CRYPTO: 'Crypto | Deposit',
		BANK_TRANSFER: 'Bank Transfer | Deposit',
		BANK_TRANSFER_INVOICE: 'Invoice | Deposit',
		BANK_TRANSFER_INVOICE_HISTORY: 'History | Deposit',
		CARD: 'Card | Deposit',
	},
	WITHDRAW: {
		INDEX: 'Withdraw',
		CRYPTO: {
			INDEX: 'Crypto | Withdraw',
			CONFIRMATION: 'Confirmation | Crypto Withdraw',
			SUMMARY: 'Summary | Crypto Withdraw',
			TWO_FA: 'TwoFa | Crypto Withdraw',
		},
		CARD: 'Card | Withdraw',
	},
};
