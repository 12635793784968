import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import OplatiLayout from '../../../components/deposit/oplati';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAccessToken } from '../../../services/auth';
import { useQuery } from '@tanstack/react-query';
import { fetchOplatiDeposit } from '../../../api/deposit';

const OplatiDeposit = () => {
	const dispatch = useAppDispatch();
	const accessToken = useAppSelector(selectAccessToken);

	const { data, isPending } = useQuery({
		queryKey: ['oplatiDeposit'],
		queryFn: () =>
			fetchOplatiDeposit({
				accessToken: accessToken as string,
				dispatch: dispatch,
			}),
	});

	return (
		<>
			<Helmet>
				<title>{TITLES.DEPOSIT.OPLATI}</title>
			</Helmet>
			<OplatiLayout data={data} isLoading={isPending} />
		</>
	);
};

export default OplatiDeposit;
