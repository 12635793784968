import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import WithdrawLayout from '../../components/withdraw';

const Withdraw = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.INDEX}</title>
			</Helmet>
			<WithdrawLayout />
		</>
	);
};

export default Withdraw;
