import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import BankTransferLayout from '../../../components/deposit/bank';
import { QueryClient } from '@tanstack/react-query';
import { useAppDispatch } from '../../../hooks';
import { useLoaderData } from 'react-router-dom';
import { useEffect } from 'react';
import { setDictionary } from '../../../services/dictionaries';
import { fetchBankDepositPageInit } from '../../../api/deposit';

export const bankTransferLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['bankTransferLoader'],
		queryFn: () => fetchBankDepositPageInit(),
		staleTime: 86400
	});
};

const BankTransferDeposit = () => {
	const dispatch = useAppDispatch();
	const data = useLoaderData();

	useEffect(() => {
		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign((data as any)?.banks, {
					dictionaryName: 'banks'
				})
			)
		);
		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign((data as any)?.countries, {
					dictionaryName: 'countries'
				})
			)
		);
	}, []);

	return (
		<>
			<Helmet>
				<title>{TITLES.DEPOSIT.BANK_TRANSFER}</title>
			</Helmet>
			<BankTransferLayout />
		</>
	);
};

export default BankTransferDeposit;
