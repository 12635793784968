import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { selectPreviousMainLocation } from '../../services/root';
import { ROUTES } from '../../types/routes';
import { mapClassnameForMobile } from '../../utils';
import { HeaderLayout } from '../common/header';
import { DepositIcons, RightChev } from '../common/icons';

const WithdrawLayout = () => {
	const previousMainLocation = useAppSelector(selectPreviousMainLocation);

	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const handleBackClickButton = () => {
		navigate(previousMainLocation ? previousMainLocation : ROUTES.WALLET.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout
					isFunctionalHeader
					isAboutHeader
					handleBackClick={handleBackClickButton}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">{t('wallet_withdraw')}</h1>
						<h1 className="depositSubtitleText">{t('withdraw_subtitle')}</h1>
					</div>
					<div className="depositButtonsBottomContainer">
						<div className="commonContainer">
							<div className="commonItem">
								<DepositIcons.CreditCardDepositIcon />
								<h1 className="commonText">{t('general_creditCard')}</h1>
								<RightChev />
							</div>
						</div>

						<div
							onClick={() => navigate(ROUTES.WITHDRAW.CRYPTO.INDEX)}
							className="commonContainer"
						>
							<div className="commonItem">
								<DepositIcons.CryptoDepositIcon />
								<h1 className="commonText">{t('general_crypto')}</h1>
								<RightChev />
							</div>
						</div>

						<div
							className="commonContainer"
						>
							<div className="commonItem">
								<DepositIcons.BankDepositIcon />
								<h1 className="commonText">
									{t('general_bankTransfer')}
								</h1>
								<RightChev />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WithdrawLayout;
