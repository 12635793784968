export const API_ROUTES = {
	LOGIN: {
		METHOD: 'POST',
		URL: '/api/token/login',
	},
	REFRESH_TOKEN: {
		METHOD: 'GET',
		URL: '/api/token/refresh',
	},
	SEND_OTP: {
		METHOD: 'POST',
		URL: '/api/token/login/twofa/otp/send',
	},
	REVOKE: {
		METHOD: 'DELETE',
		URL: '/api/token/revoke',
	},
	DICTIONARY: {
		METHOD: 'GET',
		URL: '/api/dictionaries',
	},
	HEALTH: {
		METHOD: 'GET',
		URL: '/api/health',
	},
	SECURITY: {
		NEWKEY: {
			METHOD: 'GET',
			URL: '/api/security/twofa/googleauthenticator/newkey',
		},
		ENABLE_GA: {
			METHOD: 'POST',
			URL: '/api/security/twofa/googleauthenticator/enable',
		},
	},
	MARKET: {
		PRICES: {
			METHOD: 'POST',
			URL: '/api/assets/prices',
		},
	},
	OPERATIONS: {
		CANCEL: {
			METHOD: 'GET',
			URL: '/api/operations/cancel/{operId}',
		},
		DETAILS: {
			METHOD: 'GET',
			URL: '/api/operations/details/{operId}',
		},
		LIST: {
			METHOD: 'GET',
			URL: '/api/operations/list/{filter}',
		},
		STATEMENT: {
			METHOD: 'GET',
			URL: '/api/operations/list/{filter}/statement',
		},
		DEPOSIT: {
			ERIP_DEPOSIT: {
				METHOD: 'GET',
				URL: '/api/operations/erip/deposit',
			},
			OPLATI_DEPOSIT: {
				METHOD: 'GET',
				URL: '/api/operations/oplati/deposit',
			},
			CRYPTO_DEPOSIT_ADDRESS: {
				METHOD: 'GET',
				URL: '/api/operations/crypto/{networkName}/deposit'
			},
			CARD: {
				CARD_DEPOSIT: {
					METHOD: 'POST',
					URL: '/api/operations/card/deposit',
				},
				CARD_COMMISSION: {
					METHOD: 'POST',
					URL: '/api/operations/card/deposit/commission'
				},
			},
			BANK: {
				CREATE_BANK_INVOICE: {
					METHOD: 'POST',
					URL: '/api/operations/bank/deposit',
				},
				GET_BANK_INVOICE: {
					METHOD: 'GET',
					URL: '/api/operations/bank/deposit/invoices/{invoiceId}/pdf'
				},
				GET_BANK_INVOICE_DETAILS: {
					METHOD: 'GET',
					URL: '/api/operations/bank/deposit/invoices/{invoiceId}'
				},
				DELETE_BANK_INVOICE: {
					METHOD: 'DELETE',
					URL: '/api/operations/bank/deposit/invoices/{invoiceId}'
				},
				INVOICE_HISTORY: {
					METHOD: 'GET',
					URL: '/api/operations/bank/deposit/invoices'
				},
				BANK_COMMISSION: {
					METHOD: 'GET',
					URL: '/api/operations/bank/deposit/commission'
				},
			}
		},
		WITHDRAW: {
			CRYPTO: {
				WITHDRAW: {
					METHOD: 'POST',
					URL: '/api/operations/crypto/{networkName}/withdraw'
				},
				CHECK_ADDRESS: {
					METHOD: 'GET',
					URL: '/api/operations/crypto/{networkName}/check/{cryptoAddress}'
				},
				COMMISSION: {
					METHOD: 'GET',
					URL: '/api/operations/crypto/{networkName}/withdraw/commission'
				},
				CREATE_TEMPLATE: {
					METHOD: 'POST',
					URL: '/api/operations/crypto/{networkName}/template'
				},
				GET_TEMPLATE: {
					METHOD: 'GET',
					URL: '/api/operations/crypto/{networkName}/template'
				},
				UPDATE_TEMPLATE: {
					METHOD: 'PATCH',
					URL: '/api/operations/crypto/{networkName}/template/{templateId}'
				},
				DELETE_TEMPLATE: {
					METHOD: 'DELETE',
					URL: '/api/operations/crypto/{networkName}/template/{templateId}'
				},
			}
		},
	},
	USER: {
		VERIFICATION: {
			INFO: {
				METHOD: 'GET',
				URL: '/api/users/verification/info',
			},
		},
		INFO: {
			METHOD: 'GET',
			URL: '/api/users/profile/info',
		},
		GENERATE_ACCOUNT_CONFIRMATION_LETTER: {
			METHOD: 'POST',
			URL: '/api/users/profile/accountLetter',
		},
	},
	WALLET: {
		TRANSFER_RESTS_TO_MAIN_WALLET: {
			METHOD: 'POST',
			URL: '/api/operations/transfer/batch/toacc',
		},
		ACCOUNTS_INFO: {
			METHOD: 'GET',
			URL: '/api/accounts/info',
		},
		ACCOUNT_REST: {
			METHOD: 'GET',
			URL: '/api/accounts/rests',
		},
		ACCOUNTS_MARGIN_RESTS: {
			METHOD: 'GET',
			URL: '/api/accounts/rests/margin',
		},
		CONVERT_LOW_BALANCES: {
			METHOD: 'POST',
			URL: '/api/operations/exchange/convertlow',
		},
	},
};
