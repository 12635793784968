import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { HeaderLayout } from '../../../common/header';
import { mapClassnameForMobile } from '../../../../utils';
import { ROUTES } from '../../../../types/routes';
import {
	useGetBankDepositInvoice,
	useGetBankDepositInvoiceHistory,
} from '../../../../data/mutations/deposit';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { selectAccessToken } from '../../../../services/auth';
import { useEffect, useState } from 'react';
import { BankDepositInvoiceHistoryList } from '../../../common/lists';
import { APIError } from '../../../common/error';
import { BankDepositInvoiceHistoryModal } from '../../../common/modals';
import { setCurrentInvoiceId } from '../../../../services/deposit';

const BankTransferInvoiceHistoryLayout = () => {
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const { isError, error, isPending, getBankDepositInvoiceHistory } =
		useGetBankDepositInvoiceHistory(dispatch);

	const { isBankDepositInvoicePending, getBankDepositInvoice } =
		useGetBankDepositInvoice(dispatch);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleItemClick = (i: string) => {
		handleModalOpen();
		dispatch(setCurrentInvoiceId(i));
		getBankDepositInvoice({
			accessToken: accessToken as string,
			dispatch: dispatch,
			invoiceId: i,
		});
	};

	useEffect(() => {
		getBankDepositInvoiceHistory({
			accessToken: accessToken as string,
			dispatch: dispatch,
		});
	}, []);

	const handleBackClickButton = () => {
		navigate(ROUTES.DEPOSIT.BANK_TRANSFER);
	};

	return (
		<div className={'component'}>
			<div
				style={{
					height: isPending ? 'auto' : 'auto',
				}}
				className={'innerComponent aboutComponent'}
			>
				<HeaderLayout
					isFunctionalHeader
					isAboutHeader
					handleBackClick={handleBackClickButton}
				/>
				<BankDepositInvoiceHistoryModal
					isLoading={isBankDepositInvoicePending}
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleModalOpen}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('invoice_invoiceHistory')}
						</h1>
						<BankDepositInvoiceHistoryList
							isLoading={isPending}
							handleItemClick={handleItemClick}
						/>
						<APIError isError={isError} error={error} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default BankTransferInvoiceHistoryLayout;
