import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks';
import { selectInvoiceInfo, selectInvoiceLink } from '../../../../services/deposit';
import { HeaderLayout } from '../../../common/header';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../common/buttons';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../../types/routes';
import { CommunityIcons, DepositIcons } from '../../../common/icons';
import { BankTransferDepositDetailsModal } from '../../../common/modals';

interface BankTransferInvoicePreviewLayoutProps {
	isLoading?: boolean;
}

const BankTransferInvoicePreviewLayout = ({
	isLoading,
}: BankTransferInvoicePreviewLayoutProps) => {
	const invoiceInfo = useAppSelector(selectInvoiceInfo);
	const invoiceLink = useAppSelector(selectInvoiceLink);

	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [fileName, setFileName] = useState<string>('');

	const handleInvoicePreviewOpen = () => {
		window.open(invoiceLink, '_blank');
	};

	useEffect(() => {
		if (isLoading) {
			return;
		}
		if (!isLoading && invoiceInfo === undefined) {
			navigate(ROUTES.DEPOSIT.BANK_TRANSFER);
		}
		if (invoiceInfo) {
			setFileName(
				`${t('deposit_title')}_${
					invoiceInfo?.find(
						(invoiceObj) => invoiceObj?.paramId === 'beneficiaryBank'
					)?.paramValue
				}.pdf`
			);
		}
	}, [invoiceInfo, isLoading]);

	if (isLoading) {
		return <div className="invoicePreviewContainerLoading" />;
	}

	return (
		<div className="invoicePreviewContainer">
			<div className="headerInvoicePreviewContainer">
				<DepositIcons.InvoicePreviewIcon
					onClick={handleInvoicePreviewOpen}
					className="invoicePreviewIcon"
				/>
			</div>
			<div className="bottomInvoicePreviewContainer">
				<div className="bottomInvoicePreviewInnerContainer">
					<h1 className="invoicePreviewFileNameText">{fileName}</h1>
					<CommunityIcons.ShareIcon className='' onClick={handleInvoicePreviewOpen} />
				</div>
			</div>
		</div>
	);
};

const BankTransferInvoiceLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isAuthorizedHeader />
				<BankTransferDepositDetailsModal
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleModalOpen}
				/>
				<div className="walletLayout">
					<div className="innerContainer">
						<h1 className="depositSubtitleText">
							{t('invoice_useThisInvoiceForBankTransfer')}
						</h1>
						<BankTransferInvoicePreviewLayout />
					</div>
				</div>
			</div>
			<div className="depositButtonsBottomContainer">
				<CustomButton
					text={t('invoice_bankCredentials') as string}
					onClick={handleModalOpen}
					isGrey
					className="confirmFullWidthButton"
				/>
				<CustomButton
					text={t('general_finishAndClose') as string}
					onClick={() => navigate(ROUTES.WALLET.INDEX)}
					className="confirmFullWidthButton"
				/>
			</div>
		</div>
	);
};

export { BankTransferInvoiceLayout, BankTransferInvoicePreviewLayout };
