import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import {
	selectCryptoWithdrawAddress,
	selectCryptoWithdrawAmount,
	selectCryptoWithdrawAsset,
	selectCryptoWithdrawFee,
	selectCryptoWithdrawNetwork,
	selectCryptoWithdrawOperationId,
	selectCryptoWithdrawSavedAddresses,
	setCryptoWithdrawInit,
} from '../../../../services/withdraw';
import { ROUTES } from '../../../../types/routes';
import { HeaderLayout } from '../../../common/header';
import { formatNumber } from '../../../../utils';
import { CustomButton } from '../../../common/buttons';
import { useTranslation } from 'react-i18next';
import { selectMainAccountRests } from '../../../../services/wallet';
import { TAsset } from '../../../../types/types';
import { WhaleIcon } from '../../../common/icons';
import { RejectOperationModal } from '../../../common/modals';
import { useCreateCryptoWithdrawSavedAddresses } from '../../../../data/mutations/withdraw';
import { selectAccessToken } from '../../../../services/auth';

const CryptoWithdrawSummaryLayout = () => {
	const selectedNetwork = useAppSelector(selectCryptoWithdrawNetwork);
	const accessToken = useAppSelector(selectAccessToken);
	const address = useAppSelector(selectCryptoWithdrawAddress);
	const fee = useAppSelector(selectCryptoWithdrawFee);
	const amount = useAppSelector(selectCryptoWithdrawAmount);
	const selectedAsset = useAppSelector(selectCryptoWithdrawAsset);
	const operationId = useAppSelector(selectCryptoWithdrawOperationId);
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const savedAddresses = useAppSelector(selectCryptoWithdrawSavedAddresses);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const [mainAsset, setMainAsset] = useState<TAsset>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { createCryptoWithdrawSavedAddresses } =
		useCreateCryptoWithdrawSavedAddresses();

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const clearCryptoWithdrawState = () => {
		dispatch(setCryptoWithdrawInit());
		navigate(ROUTES.WALLET.INDEX);
	};

	useEffect(() => {
		if (address === '' || amount === '') {
			dispatch(setCryptoWithdrawInit());
			navigate(ROUTES.WALLET.INDEX);
		}
		if (mainCurrencies) {
			setMainAsset(
				mainCurrencies?.assets?.find(
					(asset) => asset?.code === selectedAsset?.code
				)
			);
		}
		if (
			savedAddresses?.filter(
				(tempAddress) => tempAddress?.cryptoAddress === address
			)?.length === 0
		) {
			createCryptoWithdrawSavedAddresses({
				accessToken: accessToken as string,
				dispatch: dispatch,
				templateName: `${selectedAsset?.code}_${operationId?.substring(0, 4)}`,
				cryptoAddress: address,
				networkName: selectedNetwork?.networkName as string,
			});
		}
	}, []);

	const handleBackClickButton = () => {
		dispatch(setCryptoWithdrawInit());
		navigate(ROUTES.WALLET.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isRejectHeader handleBackClick={handleModalOpen} />
				<RejectOperationModal
					handleIsModalOpen={handleModalOpen}
					isModalOpen={isModalOpen}
					operId={operationId}
					additionalFunc={clearCryptoWithdrawState}
				/>
				<div
					style={{
						zIndex: '1',
					}}
					className="walletLayout authMainContainer"
				>
					<WhaleIcon className="whaleConvertIcon" />
					<h1 className={'modalWalletConvertSummaryHeader'}>
						{t('operation_operationIsProcessed')}
					</h1>
					<h1 className={'modalWalletConvertSummaryBottom'}>
						{t('operation_conversionProcessedDetails')}
					</h1>
				</div>
			</div>
			<div
				style={{
					zIndex: '3',
				}}
				className="depositButtonsBottomContainer"
			>
				<div className="commonGreyContainer">
					<div className="commonItem">
						<h1 className="cryptoWithdrawCommonItemLeftText">
							{t('withdraw_totalWithdraw')}
						</h1>
						<h1 className="cryptoWithdrawCommonItemRightText">
							{formatNumber(amount, mainAsset?.precision)}
							{` ${selectedAsset?.code}`}
						</h1>
					</div>
					<div className="commonItem">
						<h1 className="cryptoWithdrawCommonItemLeftText">
							{t('withdrawConfirmation_withdrawFee')}
						</h1>
						<h1 className="cryptoWithdrawCommonItemRightText">
							{formatNumber(fee, mainAsset?.precision)}
							{` ${selectedAsset?.code}`}
						</h1>
					</div>
					<div className="commonItem">
						<h1
							style={{
								width: '60px !important',
							}}
							className="cryptoWithdrawCommonItemLeftText"
						>
							{t('withdrawCryptoResult_toAddress')}
						</h1>
						<h1
							style={{
								wordBreak: 'break-word',
								width: '200px !important',
							}}
							className="cryptoWithdrawCommonItemRightText"
						>
							{address}
						</h1>
					</div>
				</div>
				<CustomButton
					text={t('general_finishAndClose') as string}
					onClick={handleBackClickButton}
					className="confirmFullWidthButton"
				/>
			</div>
		</div>
	);
};

export default CryptoWithdrawSummaryLayout;
