import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { HeaderLayout } from '../../common/header';
import { ROUTES } from '../../../types/routes';
import { mapClassnameForMobile } from '../../../utils';
import { CopyWhiteIcon, DepositIcons } from '../../common/icons';
import { TDepositResponse } from '../../../types/types';
import { CustomButton } from '../../common/buttons';

interface Props {
	data: TDepositResponse;
	isLoading: boolean;
}
const EripLayout = ({ data, isLoading }: Props) => {
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const handleBackClickButton = () => {
		navigate(ROUTES.DEPOSIT.INDEX);
	};

	const handleCopyClick = () => {
		navigator.clipboard.writeText(data?.accountNumber);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">{t('depositErip_title')}</h1>
						<DepositIcons.BigEripDepositIcon className="bigDepositIconContainer" />
						<h1 className="depositInnerHeaderContainerText">
							{t('depositErip_descriptionTitle')}
						</h1>
						<div className="depositInnerContainerList">
							<h1 className="depositInnerContainerListText">
								{t('depositErip_step1')}
							</h1>
							<h1 className="depositInnerContainerListText">
								{t('depositErip_step2')}
							</h1>
							<h1 className="depositInnerContainerListText">
								{t('depositErip_step3')}
							</h1>
							<h1 className="depositInnerContainerListText">
								{t('depositErip_step4')}
							</h1>
							<h1 className="depositInnerContainerListText">
								{t('depositErip_step5')}
							</h1>
						</div>
						{isLoading ? (
							<div className="depositEWalletCopyContainerLoading" />
						) : (
							<div className="depositEWalletCopyContainer">
								<h1 className="depositEWalletCopyText">
									{data?.accountNumber}
								</h1>
								<CopyWhiteIcon className="" onClick={handleCopyClick} />
							</div>
						)}
						{data?.qrCode && (
							<div className="depositEWalletLinkContainer">
								<div className="depositEWalletSeparateContainer">
									<div className="depositEWalletSeparator" />
									<h1 className="depositEWalletSeparateText">
										{t('depositErip_or')}
									</h1>
									<div className="depositEWalletSeparator" />
								</div>
								<CustomButton
									text={t('depositOplati_actionTitle') as string}
									className="confirmFullWidthButton"
									onClick={() => window.open(data?.qrCode, '_blank')}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default EripLayout;
