import { useEffect, useMemo, useState } from 'react';
import {
	HistoryItem,
	InvoiceBankDepositItem,
	MarketAssetItem,
	SearchAssetItem,
	WalletItem,
} from './items';
import {
	TAsset,
	TBankDepositInvoiceHistory,
	TMarketAsset,
	TMarketTab,
	TOperation,
	TSavedAddress,
	TWalletModalItem,
} from '../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectBasePrecision,
	selectCommissionAccountRests,
	selectGlobalAsset,
	selectIsEmpty,
	selectMainAccountRests,
	selectMarginAccountsRests,
	selectSpotAccountsRests,
	selectWalletConvertAssets,
	selectWalletTransferToMainRestsAssets,
	setIsEmpty,
} from '../../../services/wallet';
import { useTranslation } from 'react-i18next';
import {
	DepositIcons,
	FavouriteMarketAssetsEmptyIcon,
	LeverageIcon,
	TransferSpotIcon,
	WhaleIcon,
} from '../icons';
import WalletEmptyList from '../../wallet/empty-wallet';
import { selectMarketAssets, setFavouriteAssets } from '../../../services/market';
import {
	selectHistoryOperationsList,
	selectIsHistoryOperationsListEmpty,
} from '../../../services/history';
import {
	selectInvoiceHistory,
	selectInvoiceHistoryIsEmpty,
} from '../../../services/deposit';
import {
	selectBanksDictionary,
	selectDepositWithdrawAssetsDictionary,
} from '../../../services/dictionaries';
import { selectCryptoWithdrawSavedAddresses } from '../../../services/withdraw';

interface SavedAddressItemListProps {
	isLoading?: boolean;
	handleItemClick: (i: string) => void;
	handleModalOpen: (address: TSavedAddress | undefined) => void;
}

const SavedAddressItemList = ({
	isLoading,
	handleItemClick,
	handleModalOpen,
}: SavedAddressItemListProps) => {
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw?.crypto;
	const savedAddresses = useAppSelector(selectCryptoWithdrawSavedAddresses);

	if (!isLoading && savedAddresses?.length === 0) {
		return <SavedAddressesEmptyList />;
	}

	const loadingCards = ['1', '2', '3', '4'];

	return (
		<div className="historyOperationsContainer">
			{isLoading
				? loadingCards.map((card) => (
						<MarketAssetItem isLoading key={card} idx={Number(card)} />
				  ))
				: savedAddresses?.map((address: TSavedAddress, idx: number) => (
						<>
							<div
								key={idx}
								className="marketAssetItem"
							>
								<img
								onClick={() => handleItemClick(address?.cryptoAddress)}
									className="assetIcon"
									src={
										depositWithdrawAssetsDictionary?.find(
											(network) =>
												network?.networkName ===
												address?.networkName
										)?.networkIcon
									}
								/>
								<div className="marketAssetItem">
									{isLoading ? (
										<div className="marketAssetItemNameContainer">
											<h1 className="upperMarketItemTextLoading" />
											<h1 className="bottomMarketItemTextLoading" />
										</div>
									) : (
										<div onClick={() => handleItemClick(address?.cryptoAddress)} className="historyItemNameContainer">
											<h1 className="upperMarketItemText">
												{address?.templateName}
											</h1>
											<h1 className="bottomMarketItemText">
												{address?.networkName}
											</h1>
										</div>
									)}
									<div className="marketAssetItemRateContainer">
										<DepositIcons.SavedAddressEditIcon
											className=""
											onClick={() => handleModalOpen(address)}
										/>
									</div>
								</div>
							</div>
						</>
				  ))}
		</div>
	);
};

interface HistoryItemListProps {
	isLoading?: boolean;
	handleItemClick: (i: TOperation) => void;
}

const HistoryItemList = ({ isLoading, handleItemClick }: HistoryItemListProps) => {
	const historyOperationsList = useAppSelector(selectHistoryOperationsList);

	const isHistoryOperationsListEmpty = useAppSelector(
		selectIsHistoryOperationsListEmpty
	);

	if (!isLoading && isHistoryOperationsListEmpty) {
		return <HistoryEmptyList />;
	}

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	return (
		<div className="historyOperationsContainer">
			{isLoading
				? loadingCards.map((card) => (
						<MarketAssetItem isLoading key={card} idx={Number(card)} />
				  ))
				: historyOperationsList?.map((operation: TOperation, idx: number) => (
						<HistoryItem
							handleItemClick={handleItemClick}
							item={operation}
							idx={idx}
						/>
				  ))}
		</div>
	);
};

const HistoryEmptyList = () => {
	const { t } = useTranslation();

	return (
		<div className="walletConfirmIdentityContainer">
			<WhaleIcon className="walletConfirmIdentityWhale" />
			<h1 className="walletConfirmIdentityHeader">
				{t('history_noTransactionsYet')}
			</h1>
		</div>
	);
};

const SavedAddressesEmptyList = () => {
	const { t } = useTranslation();

	return (
		<div className="walletConfirmIdentityContainer">
			<WhaleIcon className="walletConfirmIdentityWhale" />
			<h1 className="walletConfirmIdentityHeader">
				{t('general_noResultsFound')}
			</h1>
		</div>
	);
};

interface WalletItemListProps {
	isLowBalanceHidden: boolean;
	isLoading?: boolean;
	isBalanceHidden: boolean;
	globalAsset: TWalletModalItem;
	searchValue?: string;
	isWalletConvertModal?: boolean;
	isWalletTransferModal?: boolean;
	handleSelectedAmountChange?: (i: TAsset, isSelected: boolean, idx: number) => void;
	selected?: boolean[];
	handleWalletTransferRestsToMainModalOpen?: (isLeverageTransfer?: boolean) => void;
}

const WalletItemList = ({
	isLowBalanceHidden,
	isLoading,
	globalAsset,
	isBalanceHidden,
	searchValue,
	isWalletConvertModal,
	isWalletTransferModal,
	handleSelectedAmountChange,
	selected,
	handleWalletTransferRestsToMainModalOpen,
}: WalletItemListProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const commissionCurrencies = useAppSelector(selectCommissionAccountRests);
	const spotCurrencies = useAppSelector(selectSpotAccountsRests);
	const marginCurrencies = useAppSelector(selectMarginAccountsRests);
	const isEmpty = useAppSelector(selectIsEmpty);

	const walletConvertAssets = useAppSelector(selectWalletConvertAssets);
	const walletTransferAssets = useAppSelector(selectWalletTransferToMainRestsAssets);

	const minHideValue = mainCurrencies?.baseLowBalance as number;

	function filterCurrenciesByMinHideValue(
		currencies: TAsset[] | undefined,
		isLowBalanceHidden: boolean,
		searchValue: string | undefined
	) {
		if (currencies) {
			if (searchValue) {
				return currencies.filter(
					(currency) =>
						currency.code.toLowerCase().includes(searchValue) ||
						currency.name.toLowerCase().includes(searchValue)
				);
			}
			if (isLowBalanceHidden) {
				return currencies.filter((currency) =>
					currency?.baseAvailable
						? currency.baseAvailable > minHideValue
						: currency.available > minHideValue
				);
			} else {
				return currencies;
			}
		}
	}

	const visibleMainCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				mainCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[mainCurrencies, isLowBalanceHidden, searchValue]
	);

	const visibleSpotCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				spotCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[spotCurrencies, isLowBalanceHidden, searchValue]
	);
	const visibleMarginCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				marginCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[marginCurrencies, isLowBalanceHidden, searchValue]
	);

	const visibleCommissionCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				commissionCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[commissionCurrencies, isLowBalanceHidden, searchValue]
	);

	useEffect(() => {
		if (!isLowBalanceHidden) {
			if (mainCurrencies) {
				const localIsEmpty =
					(visibleCommissionCurrencies?.length ?? 0) +
						(visibleMainCurrencies?.length ?? 0) +
						(visibleMarginCurrencies?.length ?? 0) +
						(visibleSpotCurrencies?.length ?? 0) ===
					0;
				dispatch(setIsEmpty(localIsEmpty));
			}
		}
	}, [
		visibleCommissionCurrencies,
		visibleMainCurrencies,
		visibleMarginCurrencies,
		visibleSpotCurrencies,
		walletConvertAssets,
	]);

	const loadingCards = ['1', '2', '3', '4'];

	if (isEmpty) {
		return <WalletEmptyList />;
	}

	if (isWalletTransferModal) {
		return (
			<div className="assetsWalletSearchContainer">
				{!walletTransferAssets
					? loadingCards.map((card) => (
							<WalletItem isLoading key={card} idx={Number(card)} />
					  ))
					: walletTransferAssets?.assets.map((currency, idx: number) => (
							<WalletItem
								isChecked={(selected as boolean[])[idx]}
								handleSelectedAmountChange={handleSelectedAmountChange}
								basePrecision={mainCurrencies?.basePrecision}
								isBalanceHidden={isBalanceHidden}
								baseAsset={globalAsset.code}
								key={idx}
								idx={idx}
								asset={currency}
							/>
					  ))}
			</div>
		);
	}

	if (isWalletConvertModal) {
		return (
			<div className="assetsWalletSearchContainer">
				{!walletConvertAssets
					? loadingCards.map((card) => (
							<WalletItem isLoading key={card} idx={Number(card)} />
					  ))
					: walletConvertAssets?.assets.map((currency, idx: number) => (
							<WalletItem
								isChecked={(selected as boolean[])[idx]}
								handleSelectedAmountChange={handleSelectedAmountChange}
								basePrecision={mainCurrencies?.basePrecision}
								isBalanceHidden={isBalanceHidden}
								baseAsset={globalAsset.code}
								key={idx}
								idx={idx}
								asset={currency}
							/>
					  ))}
			</div>
		);
	}

	if (!isLoading && !isLowBalanceHidden && isEmpty) {
		return <WalletEmptyList />;
	}

	return (
		<div
			style={{
				marginBottom: '64px',
			}}
			className={'assetsContainer'}
		>
			{!visibleMainCurrencies
				? loadingCards.map((card) => (
						<WalletItem isLoading key={card} idx={Number(card)} />
				  ))
				: visibleMainCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
				  ))}

			{visibleSpotCurrencies && visibleSpotCurrencies.length > 0 && (
				<>
					<div className="innerAssetsContainer">
						<h1 className="innerAssetsHeaderText">{t('wallet_spot')}</h1>
						<div
							className="innerAssetsTransferContainer"
							onClick={() =>
								handleWalletTransferRestsToMainModalOpen &&
								handleWalletTransferRestsToMainModalOpen(false)
							}
						>
							<TransferSpotIcon />
							<h1 className="innerAssetsTransferText">
								{t('wallet_transfer')}
							</h1>
						</div>
					</div>
					{visibleSpotCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
					))}
				</>
			)}

			{visibleMarginCurrencies &&
				visibleMarginCurrencies.length > 0 &&
				!marginCurrencies?.isEmpty && (
					<>
						<div className="innerAssetsContainer">
							<h1 className="innerAssetsHeaderText">
								{t('wallet_leverage')}
							</h1>
							<LeverageIcon
								className=""
								onClick={() =>
									handleWalletTransferRestsToMainModalOpen &&
									handleWalletTransferRestsToMainModalOpen(true)
								}
							/>
						</div>
						{visibleMarginCurrencies?.map((currency, idx: number) => (
							<>
								{currency.available > 0 ? (
									<WalletItem
										basePrecision={mainCurrencies?.basePrecision}
										isBalanceHidden={isBalanceHidden}
										baseAsset={globalAsset.code}
										key={idx}
										idx={idx}
										asset={currency}
									/>
								) : (
									''
								)}
							</>
						))}
					</>
				)}

			{visibleCommissionCurrencies && visibleCommissionCurrencies.length > 0 && (
				<>
					<div className="innerAssetsContainer">
						<h1 className="innerAssetsHeaderText">
							{t('wallet_commission')}
						</h1>
						<div
							className="innerAssetsTransferContainer"
							onClick={() =>
								handleWalletTransferRestsToMainModalOpen &&
								handleWalletTransferRestsToMainModalOpen(false)
							}
						>
							<TransferSpotIcon />
							<h1 className="innerAssetsTransferText">
								{t('wallet_transfer')}
							</h1>
						</div>
					</div>
					{visibleCommissionCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
					))}
				</>
			)}
		</div>
	);
};

interface MarketAssetsListProps {
	isLoading: boolean;
	tab: TMarketTab;
	timeFrame: string;
	isSorted: number;
}

const MarketAssetsList = ({
	isLoading,
	tab,
	timeFrame,
	isSorted,
}: MarketAssetsListProps) => {
	const { t } = useTranslation();
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketAssets = useAppSelector(selectMarketAssets);

	const [marketTabAssets, setMarketTabAssets] = useState<TMarketAsset[]>();

	const dispatch = useAppDispatch();

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	const [percentChange, setPercentChange] =
		useState<keyof TMarketAsset>('percentChange24h');

	useEffect(() => {
		if (timeFrame === `1${t('filters_h')}`) {
			setPercentChange('percentChange1h');
		}
		if (timeFrame === `24${t('filters_h')}`) {
			setPercentChange('percentChange24h');
		}
		if (timeFrame === `7${t('filters_d')}`) {
			setPercentChange('percentChange7d');
		}
		if (timeFrame === `30${t('filters_d')}`) {
			setPercentChange('percentChange30d');
		}
	}, [timeFrame]);

	function filterAssetsByIsSorted(
		assets: TMarketAsset[] | undefined,
		isSorted: number
	) {
		if (assets) {
			if (isSorted === -1) {
				return assets;
			}
			if (isSorted === 1) {
				const localAssets = [...assets];
				return localAssets.sort((firstAsset, secondAsset) => {
					if (firstAsset[percentChange] > secondAsset[percentChange]) {
						return -1;
					} else if (firstAsset[percentChange] < secondAsset[percentChange]) {
						return 1;
					} else if (firstAsset[percentChange] === undefined) {
						return 1;
					} else if (secondAsset[percentChange] === undefined) {
						return -1;
					} else {
						return 0;
					}
				});
			}
			if (isSorted === 0) {
				const localAssets = [...assets];
				return localAssets.sort((firstAsset, secondAsset) => {
					if (firstAsset[percentChange] > secondAsset[percentChange]) {
						return 1;
					} else if (firstAsset[percentChange] < secondAsset[percentChange]) {
						return -1;
					} else if (firstAsset[percentChange] === undefined) {
						return -1;
					} else if (secondAsset[percentChange] === undefined) {
						return 1;
					} else {
						return 0;
					}
				});
			}
		}
	}

	useEffect(() => {
		setMarketTabAssets(filterAssetsByIsSorted(marketAssets[tab?.filter], isSorted));
	}, [marketAssets[tab?.filter], isSorted, timeFrame]);

	const handleFavouriteClick = (asset: TMarketAsset, isNeedToAdd: boolean) => {
		dispatch(setFavouriteAssets({ asset: asset, isNeedToAdd: isNeedToAdd }));
	};

	if (tab?.filter === 'crypto' && marketTabAssets?.length === 0) {
		return (
			<div
				style={{
					marginBottom: '64px',
				}}
				className="marketAssetsContainer"
			>
				<div className="marketAssetsEmptyFavouriteContainer">
					<h1 className="marketAssetsEmptyFavouriteHeader">
						{t('assets_favouriteAssets')}
					</h1>
					<h1 className="marketAssetsEmptyFavouriteBottom">
						{t('assets_favouriteAssetsDesc')}
					</h1>
					<FavouriteMarketAssetsEmptyIcon />
				</div>
			</div>
		);
	}

	return (
		<div
			style={{
				marginBottom: '64px',
			}}
			className="marketAssetsContainer"
		>
			{isLoading || !marketTabAssets
				? loadingCards.map((card) => (
						<MarketAssetItem isLoading key={card} idx={Number(card)} />
				  ))
				: marketTabAssets?.map(
						(asset: TMarketAsset, idx: number) =>
							Boolean(asset.isShowInPrices) && (
								<MarketAssetItem
									timeFrame={timeFrame}
									isFavourite={marketAssets['crypto']?.some(
										(localAsset) => asset.code === localAsset.code
									)}
									globalAssetCode={globalAsset?.code}
									key={asset.code}
									idx={idx}
									asset={asset}
									handleFavouriteClick={handleFavouriteClick}
								/>
							)
				  )}
		</div>
	);
};

const BankDepositInvoiceHistoryListEmpty = () => {
	const { t } = useTranslation();

	return (
		<div className="walletConfirmIdentityContainer">
			<WhaleIcon className="walletConfirmIdentityWhale" />
			<h1 className="walletConfirmIdentityHeader">{t('noInvoicesYet')}</h1>
		</div>
	);
};

interface BankDepositInvoiceHistoryListProps {
	isLoading: boolean;
	handleItemClick: (i: string) => void;
}

const BankDepositInvoiceHistoryList = ({
	isLoading,
	handleItemClick,
}: BankDepositInvoiceHistoryListProps) => {
	const loadingCards = ['1', '2', '3', '4', '5', '6', '7', '8'];
	const invoiceHistory = useAppSelector(selectInvoiceHistory);
	const invoiceHistoryIsEmpty = useAppSelector(selectInvoiceHistoryIsEmpty);
	const banksDictionary = useAppSelector(selectBanksDictionary);

	if (!isLoading && invoiceHistoryIsEmpty) {
		return <BankDepositInvoiceHistoryListEmpty />;
	}

	return (
		<>
			<div className="invoiceHistoryContainer">
				{isLoading && !banksDictionary?.dictionary ? (
					loadingCards.map((card) => (
						<div className="invoiceHistoryContainerLoading" key={card} />
					))
				) : (
					<div className="commonContainer">
						{invoiceHistory?.map(
							(invoice: TBankDepositInvoiceHistory, idx: number) => (
								<InvoiceBankDepositItem
									handleItemClick={handleItemClick}
									item={invoice}
									idx={idx}
								/>
							)
						)}
					</div>
				)}
			</div>
		</>
	);
};

interface SearchItemListProps {
	isLoading: boolean;
	searchValue: string;
}

const SearchItemList = ({ isLoading, searchValue }: SearchItemListProps) => {
	const loadingCards = ['1', '2', '3', '4'];

	const { t } = useTranslation();

	const mainRests = useAppSelector(selectMainAccountRests);
	const commssionRests = useAppSelector(selectCommissionAccountRests);
	const spotRests = useAppSelector(selectSpotAccountsRests);
	const marginRests = useAppSelector(selectMarginAccountsRests);
	const marketAssets = useAppSelector(selectMarketAssets);

	const globalAsset = useAppSelector(selectGlobalAsset);
	const basePrecision = useAppSelector(selectBasePrecision);

	const [isEmpty, setIsEmpty] = useState<boolean>(false);

	function filterRestsBySearchValue(
		assets: (TMarketAsset | TAsset)[] | undefined,
		searchValue: string
	) {
		if (assets) {
			if (searchValue?.trim().length === 0 || searchValue === '') {
				return assets;
			} else {
				return assets?.filter(
					(asset) =>
						asset?.name
							?.toLowerCase()
							?.includes(searchValue?.toLowerCase()) ||
						asset?.code?.toLowerCase()?.includes(searchValue?.toLowerCase())
				);
			}
		}
	}

	const mainCurrencies = useMemo(
		() => filterRestsBySearchValue(mainRests?.assets, searchValue),
		[mainRests, searchValue]
	);

	const spotCurrencies = useMemo(
		() => filterRestsBySearchValue(spotRests?.assets, searchValue),
		[spotRests, searchValue]
	);

	const marginCurrencies = useMemo(
		() => filterRestsBySearchValue(marginRests?.assets, searchValue),
		[marginRests, searchValue]
	);

	const commissionCurrencies = useMemo(
		() => filterRestsBySearchValue(commssionRests?.assets, searchValue),
		[commssionRests, searchValue]
	);

	const marketCurrencies = useMemo(
		() => filterRestsBySearchValue(marketAssets['all'], searchValue),
		[marketAssets, searchValue]
	);

	useEffect(() => {
		setIsEmpty(
			(mainCurrencies?.length ?? 0) +
				(spotCurrencies?.length ?? 0) +
				(marginCurrencies?.length ?? 0) +
				(commissionCurrencies?.length ?? 0) +
				(marketCurrencies?.length ?? 0) ===
				0
		);
	}, [
		mainCurrencies,
		spotCurrencies,
		marginCurrencies,
		commissionCurrencies,
		marketCurrencies,
	]);

	if (isEmpty && !isLoading && searchValue.length > 0) {
		return (
			<div
				style={{
					marginBottom: '64px',
				}}
				className="searchAssetsContainer"
			>
				<div className="walletConfirmIdentityContainer">
					<WhaleIcon className="walletConfirmIdentityWhale" />
					<h1 className="walletConfirmIdentityHeader">
						{t('general_noResultsFound')}
					</h1>
				</div>
			</div>
		);
	}

	return (
		<div
			style={{
				marginBottom: '64px',
			}}
			className="searchAssetsContainer"
		>
			{isLoading ? (
				<div className="searchAssetsBlock">
					{loadingCards.map((value) => (
						<SearchAssetItem isLoading={true} idx={Number(value)} />
					))}
				</div>
			) : (
				<div className="searchAssetOuterCommonContainer">
					{Number(mainCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">
								{t('wallet_title')}
							</h1>
							<div className="searchAssetsBlock">
								{mainCurrencies?.map((asset, idx) => (
									<SearchAssetItem
										basePrecision={basePrecision}
										idx={idx}
										asset={asset}
										globalAssetCode={globalAsset.code}
									/>
								))}
							</div>
						</div>
					)}
					{Number(spotCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">{t('wallet_spot')}</h1>
							<div className="searchAssetsBlock">
								{spotCurrencies?.map((asset, idx) => (
									<SearchAssetItem
										basePrecision={basePrecision}
										idx={idx}
										asset={asset}
										globalAssetCode={globalAsset.code}
									/>
								))}
							</div>
						</div>
					)}
					{Number(marginCurrencies?.length) > 0 &&
						marginCurrencies?.some(
							(asset) => (asset as TAsset)?.available > 0
						) &&
						!marginRests?.isEmpty && (
							<div className="searchAssetOuterBlock">
								<h1 className="searchAssetBlockHeader">
									{t('wallet_leverage')}
								</h1>
								<div className="searchAssetsBlock">
									{marginCurrencies?.map((asset, idx) => (
										<>
											{(asset as TAsset)?.available > 0 && (
												<SearchAssetItem
													basePrecision={basePrecision}
													idx={idx}
													asset={asset}
													globalAssetCode={globalAsset.code}
												/>
											)}
										</>
									))}
								</div>
							</div>
						)}
					{Number(commissionCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">
								{t('wallet_commission')}
							</h1>
							<div className="searchAssetsBlock">
								{commissionCurrencies?.map((asset, idx) => (
									<SearchAssetItem
										basePrecision={basePrecision}
										idx={idx}
										asset={asset}
										globalAssetCode={globalAsset.code}
									/>
								))}
							</div>
						</div>
					)}
					{Number(marketCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">
								{t('assets_title')}
							</h1>
							<div className="searchAssetsBlock">
								{marketCurrencies?.map((asset, idx) => (
									<>
										{(asset as TMarketAsset)?.isShowInPrices != 0 && (
											<SearchAssetItem
												basePrecision={basePrecision}
												idx={idx}
												asset={asset}
												globalAssetCode={globalAsset.code}
											/>
										)}
									</>
								))}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export {
	WalletItemList,
	MarketAssetsList,
	SearchItemList,
	HistoryItemList,
	BankDepositInvoiceHistoryList,
	SavedAddressItemList,
	SavedAddressesEmptyList
};
