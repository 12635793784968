import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import BankTransferInvoiceHistoryLayout from '../../../../components/deposit/bank/history';
import { useAppDispatch } from '../../../../hooks';
import { useLoaderData } from 'react-router-dom';
import { useEffect } from 'react';
import { setDictionary } from '../../../../services/dictionaries';

const BankTransferInvoiceHistoryDeposit = () => {
	const dispatch = useAppDispatch();
	const data = useLoaderData();

	useEffect(() => {
		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign((data as any)?.banks, {
					dictionaryName: 'banks',
				})
			)
		);
		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign((data as any)?.countries, {
					dictionaryName: 'countries',
				})
			)
		);
	}, []);

	return (
		<>
			<Helmet>
				<title>{TITLES.DEPOSIT.BANK_TRANSFER_INVOICE_HISTORY}</title>
			</Helmet>
			<BankTransferInvoiceHistoryLayout />
		</>
	);
};

export default BankTransferInvoiceHistoryDeposit;
