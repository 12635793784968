import { logout } from '../services/auth';
import { setDictionary } from '../services/dictionaries';
import { setAppSettings } from '../services/root';
import { AppDispatch } from '../store';
import { ROUTES } from '../types/routes';
import { TCustomNavigate, TGlobalRouteLoaderResponse } from '../types/types';
import { NumericFormat } from 'react-number-format';

const mapClassnameForMobile = (className: string, isNeedAuthMainContainer?: boolean) => {
	if (window.innerWidth <= 480) {
		return className + ' topPlacement';
	}
	return className + (isNeedAuthMainContainer ? ' authMainContainer' : '');
};

const mapTranslationKeyHeaderByName = (pageName: string) => {
	switch (pageName) {
		case 'wallet':
			return 'wallet_title';
		case 'market':
			return 'assets_title';
		case 'exchange':
			return 'wallet_exchange';
		default:
			return '';
	}
};

const mapDictionaryByName = (dictionaryName: string) => {
	switch (dictionaryName) {
		case 'countries':
			return 'countries';
		case 'legalStatements':
			return 'documents';
		case 'help':
			return 'help';
		case 'community':
			return 'community';
		case 'assetIcons':
			return 'asseticons';
		default:
			return '';
	}
};

const formatNumber = (
	number: number | string,
	precision?: number,
	splitBy?: string,
	splitByPart?: number
) => {
	return (
		<NumericFormat
			displayType={'text'}
			value={
				splitBy
					? String(number)
							?.split(splitBy)
							[splitByPart as number]?.substring(
								0,
								splitByPart === 1 ? precision : undefined
							)
					: number
			}
			thousandSeparator={splitByPart === 1 ? '' : ' '}
			decimalSeparator={','}
			decimalScale={precision ? precision : 2}
		/>
	);
};

const setDefaultDictionaries = (
	data: TGlobalRouteLoaderResponse,
	dispatch: AppDispatch,
	navigate: TCustomNavigate
) => {
	dispatch(
		setDictionary(
			Object.assign((data as TGlobalRouteLoaderResponse)?.healthData, {
				dictionaryName: 'health',
			})
		)
	);

	if ((data as TGlobalRouteLoaderResponse)?.healthData?.title) {
		navigate(ROUTES.MAINTAIN.INDEX);
	}

	dispatch(
		setDictionary(
			Object.assign((data as TGlobalRouteLoaderResponse)?.assetIconsData, {
				dictionaryName: 'assetIcons',
			})
		)
	);

	dispatch(
		setDictionary(
			Object.assign(
				(data as TGlobalRouteLoaderResponse)?.depositWithdrawAssetsData,
				{
					dictionaryName: 'depositWithdrawAssets',
				}
			)
		)
	);

	dispatch(
		setDictionary(
			Object.assign((data as TGlobalRouteLoaderResponse)?.marketTabsData, {
				dictionaryName: 'marketTabs',
			})
		)
	);

	dispatch(
		setDictionary(
			Object.assign((data as TGlobalRouteLoaderResponse)?.registrationData, {
				dictionaryName: 'registration',
			})
		)
	);

	dispatch(setAppSettings((data as TGlobalRouteLoaderResponse)?.appSettingsData));
};

const mapResponseError = (
	response: Response,
	//eslint-disable-next-line
	responseJSON: any,
	navigate?: TCustomNavigate,
	dispatch?: AppDispatch
) => {
	const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

	if (
		response.status === 401 &&
		responseJSON.code != 'VERIFICATION_CODE_INVALID' &&
		responseJSON.code != 'MULTI_FACTOR_REQUIRED'
	) {
		dispatch && dispatch(logout());
		window.location.replace(ROUTES.AUTH.INDEX.replace(':locale', locale));
		throw responseJSON;
	}
	if (response.status === 503) {
		window.location.replace(ROUTES.MAINTAIN.INDEX.replace(':locale', locale));
		throw responseJSON;
	}
	throw responseJSON;
};

export {
	mapDictionaryByName,
	mapClassnameForMobile,
	mapResponseError,
	mapTranslationKeyHeaderByName,
	formatNumber,
	setDefaultDictionaries,
};
