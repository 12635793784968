import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import CardLayout from '../../../components/deposit/card';

const CardDeposit = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.DEPOSIT.CARD}</title>
			</Helmet>
			<CardLayout />
		</>
	);
};

export default CardDeposit;
