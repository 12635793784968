import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import {
	TAssetIconsDictionary,
	TBanksDictionary,
	TCommunityDictionary,
	TCountriesDictionary,
	TDepositWithdrawAssetsDictionary,
	THealthDictionary,
	THelpDictionary,
	TLegalStatementsDictionary,
	TMarketTab,
	TPeriodFilterDictionary,
	TRegistrationDictionary
} from '../../types/types';

export const selectCountriesDictionary = (state: TRootState) =>
	state.dictionaries.countries;

export const selectLegalStatementsDictionary = (state: TRootState) =>
	state.dictionaries.legalStatements;

export const selectHelpDictionary = (state: TRootState) =>
	state.dictionaries.help;

export const selectCommunityDictionary = (state: TRootState) =>
	state.dictionaries.community;

export const selectAssetIcons = (state: TRootState) =>
	state.dictionaries.assetIcons;

export const selectHealthDictionary = (state: TRootState) =>
	state.dictionaries.health;

export const selectMarketTabs = (state: TRootState) =>
	state.dictionaries.marketTabs;

export const selectDepositWithdrawAssetsDictionary = (state: TRootState) =>
	state.dictionaries.depositWithdrawAssets;

export const selectRegistrationDictionary = (state: TRootState) =>
	state.dictionaries.registration;

export const selectPeriodFiltersDictionary = (state: TRootState) =>
	state.dictionaries.periodFilters;

export const selectBanksDictionary = (state: TRootState) => state.dictionaries.banks;

export const selectTimeout = (state: TRootState) => state.dictionaries.timeout;

interface IDictionariesState {
	countries: {
		dictionaryName: string;
		dictionary: TCountriesDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	legalStatements: {
		dictionaryName: string;
		dictionary: TLegalStatementsDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	help: {
		dictionaryName: string;
		dictionary: THelpDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	community: {
		dictionaryName: string;
		dictionary: TCommunityDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	assetIcons: {
		dictionaryName: string;
		dictionary: TAssetIconsDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	health: {
		dictionaryName: string;
		dictionary: THealthDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	marketTabs: {
		dictionaryName: string;
		dictionary: TMarketTab[] | undefined;
		version: number;
		timeStamp: number;
	};
	depositWithdrawAssets: {
		dictionaryName: string;
		dictionary: TDepositWithdrawAssetsDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	registration: {
		dictionaryName: string;
		dictionary: TRegistrationDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	periodFilters: {
		dictionaryName: string;
		dictionary: TPeriodFilterDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	banks: {
		dictionaryName: string;
		dictionary: TBanksDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	timeout: number;
	//eslint-disable-next-line
	[key: string]: any;
}

const initialState: IDictionariesState = {
	countries: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	health: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	legalStatements: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	help: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	community: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	marketTabs: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	assetIcons: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	depositWithdrawAssets: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	registration: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	periodFilters: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	banks: {
		dictionaryName: '',
		dictionary: undefined,
		version: 0,
		timeStamp: 0
	},
	timeout: 0
};

export const dictionariesSlice = createSlice({
	name: 'dictionaries',
	initialState,
	reducers: {
		setDictionary: (state, action) => {
			state[action.payload.dictionaryName] = {
				dictionary: action.payload.value,
				version: action.payload.version,
				timeStamp: Date.now() + action.payload.timeout,
				dictionaryName: action.payload.name
			};
			state.timeout = action.payload.timeout;
		}
	}
});

export const { setDictionary } = dictionariesSlice.actions;
