import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import EripLayout from '../../../components/deposit/erip';
import { useQuery } from '@tanstack/react-query';
import { fetchEripDeposit } from '../../../api/deposit';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAccessToken } from '../../../services/auth';

const EripDeposit = () => {
	const dispatch = useAppDispatch();
	const accessToken = useAppSelector(selectAccessToken);

	const { data, isPending } = useQuery({
		queryKey: ['eripDeposit'],
		queryFn: () =>
			fetchEripDeposit({
				accessToken: accessToken as string,
				dispatch: dispatch,
			}),
	});

	return (
		<>
			<Helmet>
				<title>{TITLES.DEPOSIT.ERIP}</title>
			</Helmet>
			<EripLayout data={data} isLoading={isPending} />
		</>
	);
};

export default EripDeposit;
