import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import {
	TIdentity,
	TInnerCryptoWithdraw,
	TInnerCryptoWithdrawAsset,
	TSavedAddress,
} from '../../types/types';

export const selectCryptoWithdrawAddress = (state: TRootState) =>
	state.withdrawCrypto.address;

export const selectCryptoWithdrawFee = (state: TRootState) => state.withdrawCrypto.fee;

export const selectCryptoWithdrawAsset = (state: TRootState) =>
	state.withdrawCrypto.selectedAsset;

export const selectCryptoWithdrawNetwork = (state: TRootState) =>
	state.withdrawCrypto.selectedNetwork;

export const selectCryptoWithdrawSuitableNetworks = (state: TRootState) =>
	state.withdrawCrypto.suitableNetworks;

export const selectCryptoWithdrawAmount = (state: TRootState) =>
	state.withdrawCrypto.amount;

export const selectCryptoWithdrawIsAddressValid = (state: TRootState) =>
	state.withdrawCrypto.isAddressValid;

export const selectCryptoWithdrawIsChecked = (state: TRootState) =>
	state.withdrawCrypto.isChecked;

export const selectCryptoWithdrawState = (state: TRootState) =>
	state.withdrawCrypto.state;

export const selectCryptoWithdrawIdentityTypes = (state: TRootState) =>
	state.withdrawCrypto.identityTypes;

export const selectCryptoWithdrawOperationId = (state: TRootState) =>
	state.withdrawCrypto.lastWithdrawOperationId;

export const selectCryptoWithdrawSavedAddresses = (state: TRootState) => state.withdrawCrypto.savedAddresses;

export const selectCryptoWithdrawCurrentSavedAddress = (state: TRootState) => state.withdrawCrypto.currentSavedAddress;

interface IRootInitialState {
	address: string;
	fee: string;
	selectedAsset: TInnerCryptoWithdrawAsset | undefined;
	selectedNetwork: TInnerCryptoWithdraw | undefined;
	suitableNetworks: TInnerCryptoWithdraw[] | undefined;
	amount: string;
	isAddressValid: boolean;
	isChecked: boolean;
	state: string;
	identityTypes: TIdentity[];
	lastWithdrawOperationId: string;
	savedAddresses: TSavedAddress[];
	currentSavedAddress: TSavedAddress | undefined;
}

const initialState: IRootInitialState = {
	address: '',
	fee: '0',
	selectedAsset: undefined,
	selectedNetwork: undefined,
	suitableNetworks: undefined,
	amount: '',
	isAddressValid: true,
	isChecked: false,
	state: '',
	identityTypes: [],
	lastWithdrawOperationId: '',
	savedAddresses: [],
	currentSavedAddress: undefined
};

export const withdrawCryptoSlice = createSlice({
	name: 'withdrawCrypto',
	initialState,
	reducers: {
		setSavedAddressCryptoWithdraw(state, action) {
			state.address = action.payload;
		},
		setCryptoWithdrawFee(state, action) {
			state.fee = action.payload;
		},
		setCryptoWithdrawAsset(state, action) {
			state.selectedAsset = action.payload;
		},
		setCryptoWithdrawNetwork(state, action) {
			state.selectedNetwork = action.payload;
		},
		setCryptoWithdrawSuitableNetworks(state, action) {
			state.suitableNetworks = action.payload;
		},
		setCryptoWithdrawAmount(state, action) {
			state.amount = action.payload;
		},
		setCryptoWithdrawIsAddressValid(state, action) {
			state.isAddressValid =
				action.payload === 'valid' || action.payload === 'unknown' ? true : false;
		},
		setCryptoWithdrawIsChecked(state, action) {
			state.isChecked = action.payload;
		},
		setCryptoWithdrawTwoFaInfo(state, action) {
			state.state = action.payload?.state;
			state.identityTypes = action.payload?.identityTypes;
		},
		setCryptoWithdrawOperationId(state, action) {
			state.lastWithdrawOperationId = action.payload;
		},
		setCryptoWithdrawSavedAddresses(state, action) {
			state.savedAddresses = action.payload;
		},
		setCryptoWithdrawSavedAddressesReduce(state, action) {
			state.savedAddresses = state.savedAddresses?.filter((address) => address.templateId != action.payload)
		},
		setUpdateCryptoWithdrawSavedAddresses(state, action) {
			state.savedAddresses = state.savedAddresses.map((address) => {
				if (address?.templateId === action.payload?.templateId) {
					address.templateName = action.payload?.templateName
				}
				return address;
			})
		},
		setCryptoWithdrawCurrentSavedAddress(state, action) {
			state.currentSavedAddress = action.payload;
		},
		setCryptoWithdrawInit(state) {
			state.address = '';
			state.fee = '0';
			state.amount = '';
			state.isAddressValid = true;
			state.selectedAsset = undefined;
			state.selectedNetwork = undefined;
			state.suitableNetworks = undefined;
			state.isChecked = false;
			state.state = '';
			state.identityTypes = [];
			state.lastWithdrawOperationId = '';
			state.savedAddresses = [];
			state.currentSavedAddress = undefined;
		},
	},
});

export const {
	setSavedAddressCryptoWithdraw,
	setCryptoWithdrawFee,
	setCryptoWithdrawAmount,
	setCryptoWithdrawAsset,
	setCryptoWithdrawNetwork,
	setCryptoWithdrawSuitableNetworks,
	setCryptoWithdrawIsAddressValid,
	setCryptoWithdrawInit,
	setCryptoWithdrawIsChecked,
	setCryptoWithdrawTwoFaInfo,
	setCryptoWithdrawOperationId,
	setCryptoWithdrawSavedAddresses,
	setCryptoWithdrawSavedAddressesReduce,
	setUpdateCryptoWithdrawSavedAddresses,
	setCryptoWithdrawCurrentSavedAddress
} = withdrawCryptoSlice.actions;
