import { Helmet } from "react-helmet-async";
import { TITLES } from "../../../../types/titles";
import { BankTransferInvoiceLayout } from "../../../../components/deposit/bank/invoice";


const BankTransferInvoiceDeposit = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.DEPOSIT.BANK_TRANSFER_INVOICE}</title>
			</Helmet>
			<BankTransferInvoiceLayout />
		</>
	);
};

export default BankTransferInvoiceDeposit;
