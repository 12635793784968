import { Helmet } from 'react-helmet-async';
import CryptoLayout from '../../../components/deposit/crypto';
import { TITLES } from '../../../types/titles';

const CryptoDeposit = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.DEPOSIT.CRYPTO}</title>
			</Helmet>
			<CryptoLayout />
		</>
	);
};

export default CryptoDeposit;
