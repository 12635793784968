import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { selectPreviousMainLocation } from '../../services/root';
import { ROUTES } from '../../types/routes';
import { mapClassnameForMobile } from '../../utils';
import { HeaderLayout } from '../common/header';
import { DepositIcons, RightChev } from '../common/icons';
import { useState } from 'react';
import { CardWarningModal } from '../common/modals';

const DepositLayout = () => {
	const previousMainLocation = useAppSelector(selectPreviousMainLocation);

	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const [isCardWarningModalOpen, setIsCardWarningModalOpen] =
		useState<boolean>(false);

	const handleIsCardWarningModalOpen = () => {
		setIsCardWarningModalOpen(!isCardWarningModalOpen);
	};

	const handleBackClickButton = () => {
		navigate(
			previousMainLocation ? previousMainLocation : ROUTES.WALLET.INDEX
		);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout
					isFunctionalHeader
					isAboutHeader
					handleBackClick={handleBackClickButton}
				/>
				<CardWarningModal
					isModalOpen={isCardWarningModalOpen}
					handleIsModalOpen={handleIsCardWarningModalOpen}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className='innerContainer'>
						<h1 className='innerContainerMainText'>
							{t('wallet_deposit')}
						</h1>
						<h1 className='depositSubtitleText'>
							{t('deposit_subtitle')}
						</h1>
					</div>
					<div className='depositButtonsBottomContainer'>
						<div
							onClick={handleIsCardWarningModalOpen}
							className='commonContainer'
						>
							<div className='commonItem'>
								<DepositIcons.CreditCardDepositIcon />
								<h1 className='commonText'>
									{t('general_creditCard')}
								</h1>
								<RightChev />
							</div>
						</div>

						<div
							onClick={() => navigate(ROUTES.DEPOSIT.CRYPTO)}
							className='commonContainer'
						>
							<div className='commonItem'>
								<DepositIcons.CryptoDepositIcon />
								<h1 className='commonText'>
									{t('general_crypto')}
								</h1>
								<RightChev />
							</div>
						</div>

						<div
							onClick={() =>
								navigate(ROUTES.DEPOSIT.BANK_TRANSFER)
							}
							className='commonContainer'
						>
							<div className='commonItem'>
								<DepositIcons.BankDepositIcon />
								<h1 className='commonText'>
									{t('general_bankTransfer')}
								</h1>
								<RightChev />
							</div>
						</div>

						<div
							onClick={() => navigate(ROUTES.DEPOSIT.OPLATI)}
							className='commonContainer'
						>
							<div className='commonItem'>
								<DepositIcons.OplatiDepositIcon />
								<h1 className='commonText'>
									{t('deposit_oplati')}
								</h1>
								<RightChev />
							</div>
						</div>

						<div
							onClick={() => navigate(ROUTES.DEPOSIT.ERIP)}
							className='commonContainer'
						>
							<div className='commonItem'>
								<DepositIcons.EripDepositIcon />
								<h1 className='commonText'>
									{t('deposit_erip')}
								</h1>
								<RightChev />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DepositLayout;
