import { useMutation } from '@tanstack/react-query';
import {
	fetchCryptoWithdrawAddressValidation,
	fetchCryptoWithdrawCommission,
	fetchCryptoWithdrawCreateTemplate,
	fetchCryptoWithdrawDeleteTemplate,
	fetchCryptoWithdrawTemplate,
	fetchCryptoWithdrawUpdateTemplate,
	fetchCryptoWithdrawWithoutTwoFa,
} from '../../../api/withdraw';
import {
	TCryptoWithdrawAddressValidationBody,
	TCryptoWithdrawBody,
	TCryptoWithdrawCommissionBody,
	TCryptoWithdrawCreateTemplateBody,
	TCryptoWithdrawDeleteTemplateBody,
	TCryptoWithdrawTemplateBody,
	TCryptoWithdrawUpdateTemplateBody,
	TCustomNavigate,
} from '../../../types/types';
import { AppDispatch } from '../../../store';
import {
	setCryptoWithdrawFee,
	setCryptoWithdrawIsAddressValid,
	setCryptoWithdrawOperationId,
	setCryptoWithdrawSavedAddresses,
	setCryptoWithdrawSavedAddressesReduce,
	setCryptoWithdrawTwoFaInfo,
	setUpdateCryptoWithdrawSavedAddresses,
} from '../../../services/withdraw';
import { ROUTES } from '../../../types/routes';

export function useGetCryptoWithdrawSavedAddresses(dispatch: AppDispatch) {
	const {
		mutateAsync: getCryptoWithdrawSavedAddresses,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['getCryptoWithdrawSavedAddresses'],
		mutationFn: (data: TCryptoWithdrawTemplateBody) =>
			fetchCryptoWithdrawTemplate(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.templates) {
				dispatch(setCryptoWithdrawSavedAddresses(data?.templates));
			}
		},
	});
	return {
		getCryptoWithdrawSavedAddresses,
		error,
		isError,
		isPending,
	};
}

export function useDeleteCryptoWithdrawSavedAddresses(
	dispatch: AppDispatch,
	templateId: string,
	handleIsModalOpen: () => void
) {
	const {
		mutateAsync: deleteCryptoWithdrawSavedAddresses,
		isError: isDeleteError,
		error: deleteError,
		isPending: isDeletePending,
	} = useMutation({
		mutationKey: ['getCryptoWithdrawSavedAddresses'],
		mutationFn: (data: TCryptoWithdrawDeleteTemplateBody) =>
			fetchCryptoWithdrawDeleteTemplate(data),
		onSuccess: async () => {
			dispatch(setCryptoWithdrawSavedAddressesReduce(templateId));
			handleIsModalOpen();
		},
	});
	return {
		deleteCryptoWithdrawSavedAddresses,
		deleteError,
		isDeleteError,
		isDeletePending,
	};
}

export function useCreateCryptoWithdrawSavedAddresses() {
	const {
		mutateAsync: createCryptoWithdrawSavedAddresses,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['createCryptoWithdrawSavedAddresses'],
		mutationFn: (data: TCryptoWithdrawCreateTemplateBody) =>
			fetchCryptoWithdrawCreateTemplate(data),
	});
	return {
		createCryptoWithdrawSavedAddresses,
		error,
		isError,
		isPending,
	};
}

export function useUpdateCryptoWithdrawSavedAddresses(
	dispatch: AppDispatch,
	templateName: string,
	templateId: string,
	handleIsModalOpen: () => void
) {
	const {
		mutateAsync: updateCryptoWithdrawSavedAddresses,
		isError: isUpdateError,
		error: updateError,
		isPending: isUpdatePending,
	} = useMutation({
		mutationKey: ['getCryptoWithdrawSavedAddresses'],
		mutationFn: (data: TCryptoWithdrawUpdateTemplateBody) =>
			fetchCryptoWithdrawUpdateTemplate(data),
		onSuccess: async () => {
			dispatch(
				setUpdateCryptoWithdrawSavedAddresses({
					templateId: templateId,
					templateName: templateName,
				})
			);
			handleIsModalOpen();
		},
	});
	return {
		updateCryptoWithdrawSavedAddresses,
		updateError,
		isUpdateError,
		isUpdatePending,
	};
}

export function useGetCryptoWithdrawCommission(dispatch: AppDispatch) {
	const {
		mutateAsync: getCryptoWithdrawCommission,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['getCryptoWithdrawCommission'],
		mutationFn: (data: TCryptoWithdrawCommissionBody) =>
			fetchCryptoWithdrawCommission(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.feeAmount) {
				dispatch(setCryptoWithdrawFee(data?.feeAmount));
			}
		},
	});
	return {
		getCryptoWithdrawCommission,
		error,
		isError,
		isPending,
	};
}

export function useCheckAddressValidation(dispatch: AppDispatch) {
	const {
		mutateAsync: checkAddressValidation,
		isError,
		error: error,
		isPending: isCheckAddressValidationPending,
	} = useMutation({
		mutationKey: ['checkAddressValidation'],
		mutationFn: (data: TCryptoWithdrawAddressValidationBody) =>
			fetchCryptoWithdrawAddressValidation(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.result) {
				dispatch(setCryptoWithdrawIsAddressValid(data?.result));
			}
		},
	});
	return {
		checkAddressValidation,
		error,
		isError,
		isCheckAddressValidationPending,
	};
}

export function useSendCryptoWithdraw(dispatch: AppDispatch, navigate: TCustomNavigate) {
	const {
		mutateAsync: sendCryptoWithdraw,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['sendCryptoWithdraw'],
		mutationFn: (data: TCryptoWithdrawBody) => fetchCryptoWithdrawWithoutTwoFa(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.idPayments) {
				dispatch(setCryptoWithdrawOperationId(data?.idPayments[0]));
				navigate(ROUTES.WITHDRAW.CRYPTO.SUMMARY);
			}
		},
		//eslint-disable-next-line
		onError: async (data: any) => {
			if (data?.data) {
				dispatch(setCryptoWithdrawTwoFaInfo(data?.data));
				navigate(ROUTES.WITHDRAW.CRYPTO.TWO_FA);
			}
		},
	});
	return {
		sendCryptoWithdraw,
		error,
		isError,
		isPending,
	};
}
