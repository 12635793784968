import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { mapClassnameForMobile } from '../../../utils';
import { HeaderLayout } from '../../common/header';
import { CustomInput } from '../../common/inputs';
import { useEffect, useState } from 'react';
import {
	TBankCountry,
	TCountry,
	TInnerBank,
	TWalletModalItem,
} from '../../../types/types';
import {
	selectAssetIcons,
	selectBanksDictionary,
	selectCountriesDictionary,
	selectDepositWithdrawAssetsDictionary,
} from '../../../services/dictionaries';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAccessToken } from '../../../services/auth';
import {
	BankTransferDepositBankModal,
	BankTransferDepositCountryModal,
	CardDepositAssetModal,
} from '../../common/modals';
import { selectGlobalAsset, selectMainEWallet } from '../../../services/wallet';
import { CustomButton } from '../../common/buttons';
import {
	useCreateBankInvoice,
	useGetBankCommission,
} from '../../../data/mutations/deposit';
import { APIError } from '../../common/error';
import { useDebounce } from '../../../hooks/useDebounce';

const BankTransferLayout = () => {
	const globalAsset = useAppSelector(selectGlobalAsset);
	const accessToken = useAppSelector(selectAccessToken);
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.deposit?.fiat[0]?.bank;
	const banksDictionary = useAppSelector(selectBanksDictionary);
	const countryDictionary = useAppSelector(selectCountriesDictionary);
	const assetIcons = useAppSelector(selectAssetIcons);
	const mainEWallet = useAppSelector(selectMainEWallet);

	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const { isError, isPending, error, createBankInvoice } = useCreateBankInvoice(
		navigate,
		dispatch
	);

	const [selectedCountry, setSelectedCountry] = useState<TBankCountry | undefined>(
		banksDictionary ? banksDictionary?.dictionary?.deposit?.countries[0] : undefined
	);

	const [selectedBank, setSelectedBank] = useState<TInnerBank | undefined>();

	const [selectedAsset, setSelectedAsset] = useState<TWalletModalItem | undefined>();

	const [minValue, setMinValue] = useState<number | undefined>(
		depositWithdrawAssetsDictionary?.find(
			(asset) => asset.code === selectedAsset?.code
		)?.min
	);

	useEffect(() => {
		if (banksDictionary) {
			setSelectedCountry(banksDictionary?.dictionary?.deposit?.countries[0]);
		}
	}, [banksDictionary]);

	useEffect(() => {
		if (depositWithdrawAssetsDictionary && banksDictionary) {
			const findedAsset =
				banksDictionary?.dictionary?.deposit?.countries[0]?.currencies[0];
			setSelectedAsset({
				code: findedAsset?.code as string,
				name: findedAsset?.name as string,
			});
			setMinValue(
				depositWithdrawAssetsDictionary?.find(
					(asset) => asset.code === findedAsset?.code
				)?.min
			);
		}
	}, [depositWithdrawAssetsDictionary, banksDictionary]);

	const [amount, setAmount] = useState<string>('');
	const [isAmountRequired, setIsAmountRequired] = useState<boolean>(false);

	const [fee, setFee] = useState<number>(0);

	const { getBankCommission } = useGetBankCommission(setFee);

	const debouncedValue = useDebounce(amount, 500);

	useEffect(() => {
		if (Number(debouncedValue) > 0) {
			getBankCommission({
				amount: Number(debouncedValue),
				accessToken: accessToken as string,
				dispatch: dispatch,
				currencyCode: selectedAsset?.code as string,
				bankCountryCode: selectedCountry?.countryCode as string,
				bankKey: selectedBank?.bankKey as string,
			});
		}
	}, [debouncedValue]);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isBankCountryModalOpen, setIsBankCountryModalOpen] = useState<boolean>(false);
	const [isBankModalOpen, setIsBankModalOpen] = useState<boolean>(false);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleBankCountryModalOpen = () => {
		setIsBankCountryModalOpen(!isBankCountryModalOpen);
	};

	const handleBankModalOpen = () => {
		setIsBankModalOpen(!isBankModalOpen);
	};

	const handleBackClickButton = () => {
		navigate(ROUTES.DEPOSIT.INDEX);
	};

	const handleNavigateClick = () => {
		navigate(ROUTES.DEPOSIT.BANK_TRANSFER_INVOICE_HISTORY);
	};

	const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAmount(e.target.value);
		setIsAmountRequired(e.target.value.length > 0);
	};

	const handleClearClick = () => {
		setAmount('');
		setFee(0)
		setIsAmountRequired(false);
	};

	const handleBankCountryItemClick = (i: TBankCountry) => {
		setIsBankCountryModalOpen(!isBankCountryModalOpen);
		setSelectedCountry(i);
		setSelectedAsset(i?.currencies[0]);

		setSelectedBank(undefined);

		setAmount('');
		setIsAmountRequired(false);
	};

	const handleBankItemClick = (i: TInnerBank) => {
		setIsBankModalOpen(!isBankModalOpen);
		setSelectedBank(i);

		setAmount('');
		setIsAmountRequired(false);
	};

	const handleItemClick = (i: TWalletModalItem) => {
		setIsModalOpen(!isModalOpen);
		setSelectedAsset(i);
		setMinValue(
			depositWithdrawAssetsDictionary?.find((asset) => asset.code === i.code)?.min
		);
		setSelectedBank(undefined);

		setAmount('');
		setIsAmountRequired(false);
	};

	const handleSubmit = async () => {
		createBankInvoice({
			accessToken: accessToken as string,
			dispatch: dispatch,
			currencyCode: selectedAsset?.code as string,
			amount: Number(amount),
			bankCountryCode: selectedCountry?.countryCode as string,
			bankKey: selectedBank?.bankKey as string,
			accountNumber: mainEWallet as string,
		});
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isInvoiceHistory
					handleBackClick={handleBackClickButton}
					handleNavigateClick={handleNavigateClick}
				/>
				<BankTransferDepositCountryModal
					isModalOpen={isBankCountryModalOpen}
					handleIsModalOpen={handleBankCountryModalOpen}
					handleItemClick={handleBankCountryItemClick}
				/>
				<BankTransferDepositBankModal
					isModalOpen={isBankModalOpen}
					handleIsModalOpen={handleBankModalOpen}
					handleItemClick={handleBankItemClick}
					currentItem={selectedBank}
					banks={selectedCountry}
					currentAsset={selectedAsset}
				/>
				<CardDepositAssetModal
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleModalOpen}
					handleItemClick={handleItemClick}
					currentAsset={globalAsset}
					isBankDeposit
					currentBankCountry={selectedCountry}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('depositBank_title')}
						</h1>
						<CustomInput
							isRequired={selectedCountry ? true : false}
							isSelectAsset
							type="text"
							id="bankDepositSelectCountry"
							onClick={handleBankCountryModalOpen}
							value={selectedCountry?.countryName as string}
							imgUrl={
								selectedCountry && countryDictionary
									? countryDictionary?.dictionary?.find(
											(localCountry: TCountry) =>
												localCountry?.code ===
												selectedCountry?.countryCode
									  )?.link
									: ''
							}
							className="confirmFullWidthButton"
							label={t('depositBank_bankCountry') as string}
						/>
						<CustomInput
							isRequired={selectedAsset != undefined ? true : false}
							isSelectAsset
							type="text"
							id="bankDepositSelectAsset"
							onClick={handleModalOpen}
							value={selectedAsset?.code as string}
							imgUrl={
								assetIcons?.dictionary &&
								assetIcons?.dictionary[
									selectedAsset?.code?.toUpperCase() as string
								]
							}
							className="confirmFullWidthButton"
							label={t('general_currency') as string}
						/>
						<CustomInput
							isRequired={selectedBank != undefined ? true : false}
							isSelectAsset
							type="text"
							id="bankDepositSelectBank"
							onClick={handleBankModalOpen}
							value={
								selectedBank != undefined
									? (selectedBank?.bankName as string)
									: ''
							}
							className="confirmFullWidthButton"
							label={t('depositBank_preferredBank') as string}
						/>
						<CustomInput
							label={`${t('wallet_toBeReceived')} (${t(
								'withdraw_min'
							)} ${minValue} ${selectedAsset?.code})`}
							className="confirmFullWidthButton"
							type="number"
							id="bankDepositAmount"
							onChange={handleAmountChange}
							onClearClick={handleClearClick}
							isRequired={isAmountRequired}
							value={amount}
						/>
						<h1 className="depositFeeText">{`${t(
							'deposit_serviceFee'
						)}: ${fee.toFixed(2)} ${selectedAsset?.code}. ${t(
							'deposit_youWillReceive'
						)}: ${
							fee === 0 && Number(amount) === 0
								? 0
								: Number(Number(amount) - fee).toFixed(2)
						} ${selectedAsset?.code}`}</h1>
						<APIError isError={isError} error={error} />
					</div>
				</div>
			</div>
			<div className="depositButtonsBottomContainer">
				<CustomButton
					isDisabled={
						amount.length === 0 ||
						Number(amount) < Number(minValue) ||
						selectedAsset === undefined ||
						selectedBank === undefined
					}
					isLoading={isPending}
					text={t('depositBank_createInvoice') as string}
					onClick={handleSubmit}
					className="confirmFullWidthButton"
				/>
			</div>
		</div>
	);
};

export default BankTransferLayout;
