import { customFetch } from '../../services';
import { removeInvoiceFromHistory, setInvoiceLink } from '../../services/deposit';
import { API_ROUTES } from '../../types/api.routes';
import {
	TBankCommissionBody,
	TCardCommissionBody,
	TCardDepositBody,
	TCreateBankInvoiceBody,
	TCryptoDepositAddressBody,
	TDefaultBody,
	TGetBankInvoiceBody,
} from '../../types/types';
import { mapResponseError } from '../../utils';
import { fetchDictionary } from '../common';

export async function fetchBankDepositPageInit() {
	const countries = await fetchDictionary('countries');
	const banks = await fetchDictionary('banks');

	return {
		countries: countries,
		banks: banks,
	};
}

export async function fetchEripDeposit({ accessToken, dispatch }: TDefaultBody) {
	const eripDepositData = await customFetch(API_ROUTES.OPERATIONS.DEPOSIT.ERIP_DEPOSIT.URL, {
		method: API_ROUTES.OPERATIONS.DEPOSIT.ERIP_DEPOSIT.METHOD,
		headers: { Authorization: `Bearer ${accessToken}` },
	});

	const jsonEripDepositData = await eripDepositData.json();
	if (eripDepositData.status != 200) {
		mapResponseError(eripDepositData, jsonEripDepositData, undefined, dispatch);
	}

	return jsonEripDepositData;
}

export async function fetchOplatiDeposit({ accessToken, dispatch }: TDefaultBody) {
	const oplatiDepositData = await customFetch(
		API_ROUTES.OPERATIONS.DEPOSIT.OPLATI_DEPOSIT.URL,
		{
			method: API_ROUTES.OPERATIONS.DEPOSIT.OPLATI_DEPOSIT.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonOplatiDepositData = await oplatiDepositData.json();
	if (oplatiDepositData.status != 200) {
		mapResponseError(oplatiDepositData, jsonOplatiDepositData, undefined, dispatch);
	}

	return jsonOplatiDepositData;
}

export async function fetchCardDeposit({
	amount,
	accountNumber,
	currencyCode,
	accessToken,
	dispatch,
}: TCardDepositBody) {
	const cardDepositData = await customFetch(API_ROUTES.OPERATIONS.DEPOSIT.CARD.CARD_DEPOSIT.URL, {
		method: API_ROUTES.OPERATIONS.DEPOSIT.CARD.CARD_DEPOSIT.METHOD,
		headers: { Authorization: `Bearer ${accessToken}` },
		body: JSON.stringify({
			amount: amount,
			accountNumber: accountNumber,
			currencyCode: currencyCode,
		}),
	});

	const jsonCardDepositData = await cardDepositData.json();
	if (cardDepositData.status != 200) {
		mapResponseError(cardDepositData, jsonCardDepositData, undefined, dispatch);
	}

	return jsonCardDepositData;
}

export async function fetchCardCommission({
	amount,
	currencyCode,
	accessToken,
	dispatch,
}: TCardCommissionBody) {
	const cardCommissionData = await customFetch(
		API_ROUTES.OPERATIONS.DEPOSIT.CARD.CARD_COMMISSION.URL,
		{
			method: API_ROUTES.OPERATIONS.DEPOSIT.CARD.CARD_COMMISSION.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				amount: amount,
				currencyCode: currencyCode,
			}),
		}
	);

	const jsonCardCommissionData = await cardCommissionData.json();
	if (cardCommissionData.status != 200) {
		mapResponseError(cardCommissionData, jsonCardCommissionData, undefined, dispatch);
	}

	return jsonCardCommissionData;
}

export async function fetchBankCommission({
	amount,
	currencyCode,
	bankCountryCode,
	bankKey,
	accessToken,
	dispatch,
}: TBankCommissionBody) {
	const bankCommissionData = await customFetch(
		API_ROUTES.OPERATIONS.DEPOSIT.BANK.BANK_COMMISSION.URL + `?${new URLSearchParams({
			amount: String(amount),
			currencyCode: currencyCode,
			bankCountryCode: bankCountryCode,
			bankKey: bankKey
		})}`,
		{
			method: API_ROUTES.OPERATIONS.DEPOSIT.BANK.BANK_COMMISSION.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonBankCommissionData = await bankCommissionData.json();
	if (bankCommissionData.status != 200) {
		mapResponseError(bankCommissionData, jsonBankCommissionData, undefined, dispatch);
	}

	return jsonBankCommissionData;
}

export async function fetchCryptoDepositAddress({
	accessToken,
	assetCode,
	networkName,
	dispatch,
}: TCryptoDepositAddressBody) {
	const cryptoDepositAddressData = await customFetch(
		API_ROUTES.OPERATIONS.DEPOSIT.CRYPTO_DEPOSIT_ADDRESS.URL.replace(
			'{networkName}',
			networkName
		) +
		`?${new URLSearchParams({
			assetCode: assetCode,
		})}`,
		{
			method: API_ROUTES.OPERATIONS.DEPOSIT.CRYPTO_DEPOSIT_ADDRESS.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonCryptoDepositAddressData = await cryptoDepositAddressData.json();
	if (cryptoDepositAddressData.status != 200) {
		mapResponseError(
			cryptoDepositAddressData,
			jsonCryptoDepositAddressData,
			undefined,
			dispatch
		);
	}

	return jsonCryptoDepositAddressData;
}

export async function fetchCreateBankInvoice({
	accessToken,
	dispatch,
	amount,
	bankCountryCode,
	bankKey,
	accountNumber,
	currencyCode,
}: TCreateBankInvoiceBody) {
	const bankInvoiceData = await customFetch(
		API_ROUTES.OPERATIONS.DEPOSIT.BANK.CREATE_BANK_INVOICE.URL,
		{
			method: API_ROUTES.OPERATIONS.DEPOSIT.BANK.CREATE_BANK_INVOICE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				amount: amount,
				bankCountryCode: bankCountryCode,
				bankKey: bankKey,
				accountNumber: accountNumber,
				currencyCode: currencyCode,
			}),
		}
	);

	const jsonBankInvoiceData = await bankInvoiceData.json();
	if (bankInvoiceData.status != 200) {
		mapResponseError(bankInvoiceData, jsonBankInvoiceData, undefined, dispatch);
	}

	const invoicePdfData = await customFetch(
		API_ROUTES.OPERATIONS.DEPOSIT.BANK.GET_BANK_INVOICE.URL.replace('{invoiceId}', jsonBankInvoiceData?.invoiceId),
		{
			method: API_ROUTES.OPERATIONS.DEPOSIT.BANK.GET_BANK_INVOICE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonInvoicePdfData = await invoicePdfData.json();
	if (bankInvoiceData.status != 200) {
		mapResponseError(invoicePdfData, jsonInvoicePdfData, undefined, dispatch);
	}

	dispatch(setInvoiceLink(jsonInvoicePdfData))

	return jsonBankInvoiceData;
}

export async function fetchGetBankInvoice({
	accessToken,
	dispatch,
	invoiceId,
}: TGetBankInvoiceBody) {
	const bankInvoiceData = await customFetch(
		API_ROUTES.OPERATIONS.DEPOSIT.BANK.GET_BANK_INVOICE.URL.replace('{invoiceId}', invoiceId),
		{
			method: API_ROUTES.OPERATIONS.DEPOSIT.BANK.GET_BANK_INVOICE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonBankInvoiceData = await bankInvoiceData.json();
	if (bankInvoiceData.status != 200) {
		mapResponseError(bankInvoiceData, jsonBankInvoiceData, undefined, dispatch);
	}

	const bankInvoiceDetailsData = await customFetch(
		API_ROUTES.OPERATIONS.DEPOSIT.BANK.GET_BANK_INVOICE_DETAILS.URL.replace(
			'{invoiceId}',
			invoiceId
		),
		{
			method: API_ROUTES.OPERATIONS.DEPOSIT.BANK.GET_BANK_INVOICE_DETAILS.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonBankInvoiceDetailsData = await bankInvoiceDetailsData.json();
	if (bankInvoiceDetailsData.status != 200) {
		mapResponseError(
			bankInvoiceDetailsData,
			jsonBankInvoiceDetailsData,
			undefined,
			dispatch
		);
	}

	return {
		invoiceData: jsonBankInvoiceDetailsData,
		pdfData: jsonBankInvoiceData,
	};
}

export async function fetchDeleteBankInvoice({
	accessToken,
	dispatch,
	invoiceId,
}: TGetBankInvoiceBody) {
	const deleteBankInvoiceDetailsData = await customFetch(
		API_ROUTES.OPERATIONS.DEPOSIT.BANK.DELETE_BANK_INVOICE.URL.replace('{invoiceId}', invoiceId),
		{
			method: API_ROUTES.OPERATIONS.DEPOSIT.BANK.DELETE_BANK_INVOICE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonDeleteBankInvoiceDetailsData = await deleteBankInvoiceDetailsData.text();
	if (deleteBankInvoiceDetailsData.status != 200) {
		mapResponseError(
			deleteBankInvoiceDetailsData,
			jsonDeleteBankInvoiceDetailsData,
			undefined,
			dispatch
		);
	}

	dispatch(removeInvoiceFromHistory(invoiceId));

	return jsonDeleteBankInvoiceDetailsData;
}

export async function fetchBankDepositInvoiceHistory({
	accessToken,
	dispatch,
}: TDefaultBody) {
	const bankDepositInvoiceHistoryData = await customFetch(
		API_ROUTES.OPERATIONS.DEPOSIT.BANK.INVOICE_HISTORY.URL,
		{
			method: API_ROUTES.OPERATIONS.DEPOSIT.BANK.INVOICE_HISTORY.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonBankDepositInvoiceHistoryData = await bankDepositInvoiceHistoryData.json();
	if (bankDepositInvoiceHistoryData.status != 200) {
		mapResponseError(
			bankDepositInvoiceHistoryData,
			jsonBankDepositInvoiceHistoryData,
			undefined,
			dispatch
		);
	}

	return jsonBankDepositInvoiceHistoryData;
}
