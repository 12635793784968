import { useTranslation } from 'react-i18next';
import { mapClassnameForMobile } from '../../../utils';
import { HeaderLayout } from '../../common/header';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomInput } from '../../common/inputs';
import { useState } from 'react';
import {
	TCryptoDepositInfo,
	TInnerCryptoDeposit,
	TInnerCryptoDepositAsset,
	TInnerCryptoWithdraw,
} from '../../../types/types';
import {
	selectAssetIcons,
	selectDepositWithdrawAssetsDictionary,
} from '../../../services/dictionaries';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { CryptoDepositAssetModal, CryptoDepositNetworkModal } from '../../common/modals';
import { CustomButton } from '../../common/buttons';
import { useGetCryptoDepositAddress } from '../../../data/mutations/deposit';
import { APIError } from '../../common/error';
import { selectAccessToken } from '../../../services/auth';
import { CopyWhiteIcon } from '../../common/icons';

const CryptoLayout = () => {
	const accessToken = useAppSelector(selectAccessToken);
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.deposit?.crypto;
	const assetIcons = useAppSelector(selectAssetIcons);

	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isNetworkModalOpen, setIsNetworkModalOpen] = useState<boolean>(false);

	const [selectedAsset, setSelectedAsset] = useState<
		TInnerCryptoDepositAsset | undefined
	>();

	const [selectedNetwork, setSelectedNetwork] = useState<
		TInnerCryptoDeposit | undefined
	>();

	const [suitableNetworks, setSuitableNetworks] = useState<
		TInnerCryptoDeposit[] | undefined
	>([]);

	const [depositInfo, setDepositInfo] = useState<TCryptoDepositInfo>();

	const { getCryptoDepositAddress, isPending, isError, error } =
		useGetCryptoDepositAddress(setDepositInfo);

	const handleBackClickButton = () => {
		navigate(ROUTES.DEPOSIT.INDEX);
	};

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleNetworkModalOpen = () => {
		setIsNetworkModalOpen(!isNetworkModalOpen);
	};

	const handleNetworkItemClick = (i: TInnerCryptoDeposit | TInnerCryptoWithdraw) => {
		setSelectedNetwork(i as TInnerCryptoDeposit);
		getCryptoDepositAddress({
			accessToken: accessToken as string,
			dispatch: dispatch,
			assetCode: selectedAsset?.code as string,
			networkName: i?.networkName?.replaceAll(' ', '') as string,
		});
	};

	const handleCopyClick = () => {
		navigator.clipboard.writeText(depositInfo?.cryptoAddress as string);
	};

	const handleItemClick = (i: TInnerCryptoDepositAsset, networkCode: string) => {
		setIsModalOpen(!isModalOpen);
		setSelectedAsset(i);
		const tempSelectedNetwork = depositWithdrawAssetsDictionary?.find(
			(network) => network.networkCode === networkCode
		);
		const tempSuitableNetworks = depositWithdrawAssetsDictionary?.filter((network) =>
			network.assets.find((asset) => asset.code === i?.code)
		);
		setSelectedNetwork(tempSelectedNetwork);
		setSuitableNetworks(tempSuitableNetworks);
		getCryptoDepositAddress({
			accessToken: accessToken as string,
			dispatch: dispatch,
			assetCode: i?.code as string,
			networkName: tempSelectedNetwork?.networkName?.replaceAll(' ', '') as string,
		});
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<CryptoDepositAssetModal
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleModalOpen}
					handleItemClick={handleItemClick}
				/>
				<CryptoDepositNetworkModal
					suitableNetworks={suitableNetworks}
					isModalOpen={isNetworkModalOpen}
					handleIsModalOpen={handleNetworkModalOpen}
					handleItemClick={handleNetworkItemClick}
					currentItem={selectedNetwork}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('depositCrypto_title')}
						</h1>
						<div className="depositAmountInputContainer">
							<CustomInput
								isRequired={selectedAsset ? true : false}
								isSelectAsset
								type="text"
								id="cryptoDepositSelectAsset"
								onClick={handleModalOpen}
								value={selectedAsset?.code as string}
								imgUrl={
									selectedAsset
										? assetIcons?.dictionary &&
										  assetIcons?.dictionary[
												selectedAsset?.code?.toUpperCase() as string
										  ]
										: ''
								}
								className="confirmFullWidthButton"
								label={t('depositCrypto_chooseDepositAsset') as string}
							/>
							{selectedAsset != undefined && (
								<h1 className="depositFeeText">{`${t(
									'deposit_minimumAmount'
								)}: ${selectedAsset?.minDeposit} ${
									selectedAsset?.code
								}`}</h1>
							)}
						</div>
						{selectedAsset != undefined && (
							<div className="depositAmountInputContainer">
								<CustomInput
									isRequired
									isSelectAsset
									isDisabled={suitableNetworks?.length === 1}
									type="text"
									id="cryptoDepositSelectNetwork"
									onClick={handleNetworkModalOpen}
									value={selectedNetwork?.networkCode as string}
									imgUrl={''}
									className="confirmFullWidthButton"
									label={t('general_network') as string}
								/>
								<h1 className="depositFeeText">{`${t(
									'depositCrypto_networkConfirmations'
								)}: ${selectedNetwork?.minConfirm}`}</h1>
							</div>
						)}
						<APIError isError={isError} error={error} />
					</div>
					{selectedAsset && selectedNetwork && (
						<div className="depositButtonsBottomContainer">
							{isPending ? (
								<div className="depositCryptoBottomContainer">
									<div className="depositCryptoWarningTextLoading" />
									<div className="depositCryptoBottomAddressContainerLoading" />
								</div>
							) : (
								<div className="depositCryptoBottomContainer">
									<h1 className="depositCryptoWarningText">
										{`${t('depositCrypto_depositWarn')}`}
										<h1 className="depositCryptoWarningTextNetwork">{` (${depositInfo?.network}) ${selectedNetwork?.networkCode}`}</h1>
									</h1>
									<div className="depositCryptoBottomAddressContainer">
										<div className="depositCryptoBottomAddressInnerContainer">
											<h1 className="depositCryptoBottomAddressInnerContainerText">
												{t('depositCrypto_depositAddress')}
											</h1>
											<div>
												<CopyWhiteIcon
													className="copyImageContainer"
													onClick={handleCopyClick}
												/>
											</div>
										</div>
										<h1 className="depositCryptoBottomAddressContainerText">
											{depositInfo?.cryptoAddress}
										</h1>
									</div>
								</div>
							)}
							<CustomButton
								isGrey
								text={t('general_finishAndClose') as string}
								onClick={handleBackClickButton}
								className="confirmFullWidthButton"
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CryptoLayout;
