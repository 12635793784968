import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import CryptoWithdrawSummaryLayout from '../../../../components/withdraw/crypto/summary';

const CryptoWithdrawSummary = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.CRYPTO.SUMMARY}</title>
			</Helmet>
			<CryptoWithdrawSummaryLayout />
		</>
	);
};

export default CryptoWithdrawSummary;
