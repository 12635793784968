import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import CryptoWithdrawLayout from '../../../components/withdraw/crypto';

const CryptoWithdraw = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.CRYPTO.INDEX}</title>
			</Helmet>
			<CryptoWithdrawLayout />
		</>
	);
};

export default CryptoWithdraw;
