import { customFetch } from '../../services';
import { API_ROUTES } from '../../types/api.routes';
import {
    TCryptoWithdrawAddressValidationBody,
    TCryptoWithdrawBody,
    TCryptoWithdrawCommissionBody,
    TCryptoWithdrawCreateTemplateBody,
    TCryptoWithdrawDeleteTemplateBody,
    TCryptoWithdrawTemplateBody,
    TCryptoWithdrawUpdateTemplateBody,
} from '../../types/types';
import { mapResponseError } from '../../utils';

export async function fetchCryptoWithdrawCommission({
    amount,
    networkName,
    asset,
    accessToken,
    dispatch,
}: TCryptoWithdrawCommissionBody) {
    const cryptoWithdrawCommissionData = await customFetch(
        API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.COMMISSION.URL.replace(
            '{networkName}',
            networkName
        ) +
        `?${new URLSearchParams({
            amount: amount,
            asset: asset,
        })}`,
        {
            method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.COMMISSION.METHOD,
            headers: { Authorization: `Bearer ${accessToken}` },
        }
    );

    const jsonCryptoWithdrawCommissionData = await cryptoWithdrawCommissionData.json();
    if (cryptoWithdrawCommissionData.status != 200) {
        mapResponseError(
            cryptoWithdrawCommissionData,
            jsonCryptoWithdrawCommissionData,
            undefined,
            dispatch
        );
    }

    return jsonCryptoWithdrawCommissionData;
}

export async function fetchCryptoWithdrawTemplate({
    networkName,
    dispatch,
    accessToken,
}: TCryptoWithdrawTemplateBody) {
    const cryptoWithdrawTemplateData = await customFetch(
        API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.GET_TEMPLATE.URL.replace(
            '{networkName}',
            networkName
        ),
        {
            method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.GET_TEMPLATE.METHOD,
            headers: { Authorization: `Bearer ${accessToken}` },
        }
    );

    const jsonCryptoWithdrawTemplateData = await cryptoWithdrawTemplateData.json();
    if (cryptoWithdrawTemplateData.status != 200) {
        mapResponseError(
            cryptoWithdrawTemplateData,
            jsonCryptoWithdrawTemplateData,
            undefined,
            dispatch
        );
    }

    return jsonCryptoWithdrawTemplateData;
}

export async function fetchCryptoWithdrawUpdateTemplate({
    networkName,
    dispatch,
    accessToken,
    templateId, templateName
}: TCryptoWithdrawUpdateTemplateBody) {
    const cryptoWithdrawUpdateTemplateData = await customFetch(
        API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.UPDATE_TEMPLATE.URL.replace(
            '{networkName}',
            networkName
        ).replace("{templateId}", templateId),
        {
            method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.UPDATE_TEMPLATE.METHOD,
            headers: { Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                templateName: templateName
            })
        }
    );

    const jsonCryptoWithdrawUpdateTemplateData = await cryptoWithdrawUpdateTemplateData.json();
    if (cryptoWithdrawUpdateTemplateData.status != 200) {
        mapResponseError(
            cryptoWithdrawUpdateTemplateData,
            jsonCryptoWithdrawUpdateTemplateData,
            undefined,
            dispatch
        );
    }

    return jsonCryptoWithdrawUpdateTemplateData;
}

export async function fetchCryptoWithdrawDeleteTemplate({
    networkName,
    dispatch,
    accessToken,
    templateId
}: TCryptoWithdrawDeleteTemplateBody) {
    const cryptoWithdrawDeleteTemplateData = await customFetch(
        API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.DELETE_TEMPLATE.URL.replace(
            '{networkName}',
            networkName
        ).replace("{templateId}", templateId),
        {
            method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.DELETE_TEMPLATE.METHOD,
            headers: { Authorization: `Bearer ${accessToken}` },
        }
    );

    const jsonCryptoWithdrawDeleteTemplateData = await cryptoWithdrawDeleteTemplateData.json();
    if (cryptoWithdrawDeleteTemplateData.status != 200) {
        mapResponseError(
            cryptoWithdrawDeleteTemplateData,
            jsonCryptoWithdrawDeleteTemplateData,
            undefined,
            dispatch
        );
    }

    return jsonCryptoWithdrawDeleteTemplateData;
}

export async function fetchCryptoWithdrawCreateTemplate({
    networkName,
    dispatch,
    accessToken,
    templateName,
    cryptoAddress
}: TCryptoWithdrawCreateTemplateBody) {
    const cryptoWithdrawCreateTemplateData = await customFetch(
        API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.CREATE_TEMPLATE.URL.replace(
            '{networkName}',
            networkName
        ),
        {
            method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.CREATE_TEMPLATE.METHOD,
            headers: { Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                templateName: templateName,
                cryptoAddress: cryptoAddress
            })
        }
    );

    const jsonCryptoWithdrawCreateTemplateData = await cryptoWithdrawCreateTemplateData.json();
    if (cryptoWithdrawCreateTemplateData.status != 200) {
        mapResponseError(
            cryptoWithdrawCreateTemplateData,
            jsonCryptoWithdrawCreateTemplateData,
            undefined,
            dispatch
        );
    }

    return jsonCryptoWithdrawCreateTemplateData;
}

export async function fetchCryptoWithdrawAddressValidation({
    cryptoAddress,
    networkName,
    accessToken,
    dispatch,
}: TCryptoWithdrawAddressValidationBody) {
    const cryptoWithdrawAddressValidationData = await customFetch(
        API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.CHECK_ADDRESS.URL.replace(
            '{networkName}',
            networkName
        ).replace('{cryptoAddress}', cryptoAddress),
        {
            method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.CHECK_ADDRESS.METHOD,
            headers: { Authorization: `Bearer ${accessToken}` },
        }
    );

    const jsonCryptoWithdrawAddressValidationData =
        await cryptoWithdrawAddressValidationData.json();
    if (cryptoWithdrawAddressValidationData.status != 200) {
        mapResponseError(
            cryptoWithdrawAddressValidationData,
            jsonCryptoWithdrawAddressValidationData,
            undefined,
            dispatch
        );
    }

    return jsonCryptoWithdrawAddressValidationData;
}

export async function fetchCryptoWithdrawWithoutTwoFa({
    cryptoAddress,
    accountNumber,
    amount,
    assetCode,
    networkCode,
    networkName,
    accessToken,
    twoFa,
    dispatch,
}: TCryptoWithdrawBody) {


    const cryptoWithdrawData = await customFetch(
        API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.WITHDRAW.URL.replace(
            '{networkName}',
            networkName
        ),
        {
            method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.WITHDRAW.METHOD,
            headers: { Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                cryptoAddress: cryptoAddress,
                accountNumber: accountNumber,
                assetCode: assetCode,
                networkCode: networkCode,
                amount: amount,
                twoFa: twoFa ? {
                    state: twoFa?.state,
                    code: twoFa?.code,
                    identityType: twoFa?.identityType
                } : undefined
            }),
        }
    );

    const jsonCryptoWithdrawData = await cryptoWithdrawData.json();
    if (cryptoWithdrawData.status != 200) {
        mapResponseError(cryptoWithdrawData, jsonCryptoWithdrawData, undefined, dispatch);
    }

    return jsonCryptoWithdrawData;
}
