import { useEffect, useState } from 'react';
import { usePinInput } from 'react-pin-input-hook';
import { useTranslation } from 'react-i18next';
import {
	TIdentity,
	TInnerCryptoWithdraw,
	TInnerCryptoWithdrawAsset,
} from '../../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { selectAccessToken } from '../../../../services/auth';
import { useSendCryptoWithdraw } from '../../../../data/mutations/withdraw';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../../types/routes';
import { mapClassnameForMobile } from '../../../../utils';
import { CustomButton } from '../../../common/buttons';
import { PinInput } from '../../../common/inputs';
import { APIError } from '../../../common/error';
import { selectMainEWallet } from '../../../../services/wallet';
import {
	selectCryptoWithdrawAddress,
	selectCryptoWithdrawAmount,
	selectCryptoWithdrawAsset,
	selectCryptoWithdrawIdentityTypes,
	selectCryptoWithdrawNetwork,
	selectCryptoWithdrawState,
} from '../../../../services/withdraw';
import { selectIsTelegram } from '../../../../services/root';

interface Props {
	address: string;

	selectedAsset: TInnerCryptoWithdrawAsset | undefined;
	selectedNetwork: TInnerCryptoWithdraw | undefined;
	amount: string;
	accessToken: string;

	accountNumber: string;
	isTelegram: boolean;
	identityTypes: TIdentity[];
	state: string;
}

const CryptoWithdrawTwoFactorFunctionalLayout = ({
	accessToken,
	accountNumber,
	address,
	amount,
	selectedAsset,
	selectedNetwork,
	isTelegram,
	identityTypes,
	state,
}: Props) => {
	const dispatch = useAppDispatch();
	const [fakeError, setFakeError] = useState<boolean>(false);

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				sendCryptoWithdraw({
					accountNumber: accountNumber,
					accessToken: accessToken,
					amount: amount,
					cryptoAddress: address,
					assetCode: selectedAsset?.code as string,
					networkCode: selectedNetwork?.networkCode as string,
					networkName: selectedNetwork?.networkName as string,
					twoFa: {
						state: state,
						code: values,
						identityType: identityTypes[0]?.name,
					},
					dispatch: dispatch,
				});
			}
		},
		type: 'numeric',
		placeholder: '',
	});

	const navigate = useCustomNavigate();

	const { isError, error, sendCryptoWithdraw, isPending } = useSendCryptoWithdraw(
		dispatch,
		navigate
	);

	const { t } = useTranslation();

	useEffect(() => {
		if (identityTypes.length === 0 && address === '') {
			navigate(ROUTES.WITHDRAW.CRYPTO.INDEX);
		}
	}, []);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	const handleBackClickButton = () => {
		navigate(ROUTES.WITHDRAW.CRYPTO.CONFIRMATION);
	};

	const handlePasteClick = async () => {
		const tempClipboardContent = await navigator.clipboard.readText();

		if (
			tempClipboardContent &&
			tempClipboardContent != '' &&
			tempClipboardContent.length === 6 &&
			!isNaN(Number(tempClipboardContent))
		) {
			setValues(tempClipboardContent.split(''));
			setFakeError(false);
			sendCryptoWithdraw({
				accountNumber: accountNumber,
				accessToken: accessToken,
				amount: amount,
				cryptoAddress: address,
				assetCode: selectedAsset?.code as string,
				networkCode: selectedNetwork?.networkCode as string,
				networkName: selectedNetwork?.networkName as string,
				twoFa: {
					state: state,
					code: tempClipboardContent,
					identityType: identityTypes[0]?.name,
				},
				dispatch: dispatch,
			});
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div className={mapClassnameForMobile('walletLayout authMainContainer')}>
					<div className="innerContainer">
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>
						<h1 className={'confirmEmailText'}>
							{t('twoFactorAuthCode_title')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_pleaseEnter6DigitCode')}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={isPending}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						<APIError isError={fakeError} error={error} />
					</div>
				</div>
				{!isTelegram && (
					<div className="pasteButtonContainer">
						<CustomButton
							isGrey
							text={t('general_pasteCode') as string}
							onClick={handlePasteClick}
							className="confirmFullWidthButton"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

const CryptoWithdrawTwoFactorLayout = () => {
	const state = useAppSelector(selectCryptoWithdrawState);
	const identityTypes = useAppSelector(selectCryptoWithdrawIdentityTypes);
	const address = useAppSelector(selectCryptoWithdrawAddress);
	const amount = useAppSelector(selectCryptoWithdrawAmount);
	const selectedAsset = useAppSelector(selectCryptoWithdrawAsset);
	const selectedNetwork = useAppSelector(selectCryptoWithdrawNetwork);
	const accessToken = useAppSelector(selectAccessToken);
	const accountNumber = useAppSelector(selectMainEWallet);
	const isTelegram = useAppSelector(selectIsTelegram);

	return (
		<CryptoWithdrawTwoFactorFunctionalLayout
			isTelegram={isTelegram}
			accountNumber={accountNumber}
			address={address}
			amount={amount}
			selectedAsset={selectedAsset}
			selectedNetwork={selectedNetwork}
			state={state}
			identityTypes={identityTypes}
			accessToken={accessToken as string}
		/>
	);
};

export default CryptoWithdrawTwoFactorLayout;
